const colors = {
  primary: "rgb(63, 81, 181)",
  secondary: "#929292",
  mainFont: "#707070",
  divider: "#00000005",
  success: "#28a745",
  warning: "#ff3f3f",
};

export default colors;
