import { css } from "@emotion/css";
import colors from "../constants/colors";

function Card({ children, isActive, className, cardRef, ...props }) {
  return (
    <div {...props} ref={cardRef} className={`${styles.container} ${isActive ? styles.active : ""} ${className || ""}`}>
      {children}
    </div>
  );
}

export default Card;

const styles = {
  container: css`
    min-height: 300px;
    min-width: 300px;
    border-radius: 15px;
    margin: 0 auto;
    background: #eee;
    --box-shadow-color: #b3b3b3;
    box-shadow: 0px 0px 17px var(--box-shadow-color);
  `,
  active: css`
    position: relative;
    ::after {
      content: "";
      position: absolute;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      top: -2px;
      left: -2px;
      border: 4px solid ${colors.primary};
      border-radius: 15px;
    }
  `,
};
