import { css } from "@emotion/css";
import { Navigate, Outlet } from "react-router-dom";
import { getCurrentUser } from "../auth/authService";

function LoginPageWrapper() {
  return (
    <div className={styles.container}>
      <div className="form-container">
        <div className="form-wrapper">
          <div className="logo-wrapper">
            <img src="/img/logo.png" />
          </div>
          {!getCurrentUser() ? <Outlet /> : <Navigate to="/my-account" />}
        </div>
      </div>
    </div>
  );
}

export default LoginPageWrapper;

const styles = {
  container: css`
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(/img/gym.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      width: 50%;
      height: 100%;
      background: #fff;
      left: 50%;
      box-sizing: border-box;
      box-shadow: 0 0 10px 2px #0000009c;
    }
    .logo-wrapper {
      width: 40%;
      margin-bottom: 50px;
    }
    .logo-wrapper img {
      width: 100%;
    }
    .form-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 60px;
    }
    .form-wrapper h4 {
      font-size: 3rem;
      font-weight: 400;
      margin: 30px 0;
    }
    .form-wrapper p {
      margin: 0;
    }

    @media (max-width: 900px) {
      .form-container {
        width: 100%;
        left: 0;
      }
    }
    @media (max-width: 470px) {
      .form-wrapper h4 {
        font-size: 2.1rem;
        font-weight: 400;
        margin: 30px 0;
      }
      .text-button {
        display: none;
      }
    }
  `,
};
