import { css } from "@emotion/css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import colors from "../constants/colors";
import LeftArrowIcon from "../icons/leftArrowIcon";

function NavigateBackButton({ containerClassName, buttonClass, text, to, navigateOptions }) {
  const navigate = useNavigate();
  function handleGoBack() {
    navigate(to ? to : -1, navigateOptions);
  }

  return (
    <div className={`${styles.container} ${containerClassName}`}>
      <Button
        onClick={handleGoBack}
        startIcon={<LeftArrowIcon size={30} color={colors.mainFont} />}
        className={`text-button ${buttonClass}`}
        variant="text"
      >
        {text}
      </Button>
    </div>
  );
}

export default NavigateBackButton;

const styles = {
  container: css`
    .text-button {
      color: ${colors.mainFont};
    }
  `,
};
