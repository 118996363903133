import { useContext, useState, useEffect } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { UserContext } from "../context/userContext";
import Card from "../common/card";
import LoadingButton from "../common/LoadingButton";
import { default as MuiButton } from "@mui/lab/LoadingButton";
import PaymentStepper from "./paymentStepper";
import { PricingsContext } from "../context/pricingsContext";
import RenewIcon from "../icons/renewIcon";
import httpService, { getErr } from "../auth/httpServices";
import config from "../config.json";

function MySubscription() {
  const { currentUser } = useContext(UserContext);
  const { pricings } = useContext(PricingsContext);
  const {
    name: subscriptionName,
    duration,
    description,
    createdAt,
    valid_till,
    status,
    subscription_identifier,
  } = currentUser.currentSubscription || {};
  const [isCancelButtonLoad, setIsCancelButtonLoad] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const userSubscriptionValidity = currentUser.currentSubscription.valid_till;

  useEffect(() => {
    if (userSubscriptionValidity && dayjs().add(-1, "day").isBefore(userSubscriptionValidity)) {
      setIsExpired(false);
    } else {
      setIsExpired(true);
    }
  }, [userSubscriptionValidity]);

  async function handleSubscripeCancel() {
    const confirmed = window.confirm("Are you sure you want to cancel your subscription?");
    if (confirmed) {
      setIsCancelButtonLoad(true);
      httpService
        .post(config.subscriptionCancelEndPoint, { subscription_identifier })
        .then((results) => {
          if (results.data.subscription.status === "Cancelled") {
            window.location.reload();
          } else {
            setIsCancelButtonLoad(false);
          }
        })
        .catch((error) => {
          getErr(error);
        });
    } else {
      return;
    }
  }

  function handleOpenPayment() {
    setIsPaymentOpen(true);
  }

  function handleClosePayment() {
    setIsPaymentOpen(false);
    localStorage.removeItem("paymentStarted");
    localStorage.removeItem("redirected");
  }

  return (
    <div className={styles.container}>
      <h1>My Subscription</h1>
      <Grid container>
        <Grid item xs={4} className="describe-titles-grid">
          <div className="status-wrapper">
            <p>Status :</p>
          </div>
          <p>Subscription Name :</p>
          <p>Duration :</p>
          <p>Description :</p>
          <p>Started At :</p>
          <p>End At :</p>
        </Grid>
        <Grid item xs={4} className="describe-data-grid">
          <div className="status-wrapper">
            <p className={status === "Active" ? "status-active" : "status-inactive"}>{status}</p>
            {isExpired && (
              <LoadingButton
                onClick={handleOpenPayment}
                className="renew-button"
                startIcon={<RenewIcon size={15} color="#fff" />}
              >
                Renew
              </LoadingButton>
            )}
          </div>
          <p>{subscriptionName}</p>
          <p>{duration} Days</p>
          <p>{description}</p>
          <p>{dayjs(createdAt).format("DD/MMM/YYYY")}</p>
          <p>{dayjs(valid_till).format("DD/MMM/YYYY")}</p>
        </Grid>
        <Grid item xs={4} className="canacel-grid">
          <div className="cancel-wrapper">
            <p>Want to cancel your subscription?</p>
            {status === "Cancelled" ? (
              <p className="cancelled">Canceling Requested</p>
            ) : (
              <MuiButton
                className="cancel-button"
                loading={isCancelButtonLoad}
                onClick={handleSubscripeCancel}
                variant="text"
              >
                Request Canceling
              </MuiButton>
            )}
          </div>
        </Grid>
      </Grid>
      {!userSubscriptionValidity && (
        <Card className="subscribe-overlay">
          <h5>You don't have any active subscription at the moment</h5>
          <p>Press the button below to choose your plan and subscribe</p>
          <LoadingButton onClick={handleOpenPayment}>Subscripe</LoadingButton>
        </Card>
      )}
      <PaymentStepper
        defaultPricing={pricings[0]}
        isOpen={isPaymentOpen}
        onClose={handleClosePayment}
      />
    </div>
  );
}

export default MySubscription;

const styles = {
  container: css`
    padding: 50px;
    position: relative;
    h1 {
      text-align: center;
      margin-top: 0;
    }
    .describe-titles-grid p {
      font-weight: 600;
    }
    .status-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .status-active {
      color: ${colors.success};
    }
    .status-inactive {
      color: ${colors.warning};
    }
    .renew-button {
      margin-left: 10px;
      height: 35px;
      width: 80px;
      font-size: 0.7rem;
    }
    .cancel-wrapper {
      background: #eee;
      border-radius: 15px;
      padding: 30px 0;
      text-align: center;
    }
    .cancel-wrapper p {
      margin-top: 0;
    }
    .cancel-button {
      color: #fff;
      background-color: ${colors.warning};
      border-radius: 10px;
      font-size: 13px;
    }
    .cancel-button:hover {
      background-color: ${colors.warning};
      opacity: 0.5;
    }
    .cancelled {
      background-color: #eee;
      color: ${colors.primary};
      opacity: 0.5;
      margin: 0;
    }
    .subscribe-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #fff;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .subscribe-overlay h5 {
      margin: 0;
      color: ${colors.primary};
      opacity: 0.7;
    }
  `,
};
