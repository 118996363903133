import { useState } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import TextInput from "../common/TextInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import LoadingButton from "../common/LoadingButton";

function LoginForm({ onChange, onSubmit, emailValue, passwordValue, rememberMeChecked, resetPasswordLink, isLoading }) {
  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <FormGroup className={styles.container}>
      <div className="inputs-container">
        <TextInput type="email" onChange={onChange} value={emailValue} name="email" autoComplete="email" className="text-input" label="Email" />
        <TextInput
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={onChange}
          value={passwordValue}
          name="password"
          className="text-input"
          label="Password"
          autoComplete="current-password"
        />
        <div className="inputs-footer">
          <FormControlLabel
            onChange={onChange}
            checked={rememberMeChecked}
            name="rememberMe"
            control={
              <Checkbox
                sx={{
                  color: colors.primary,
                  "&.Mui-checked": {
                    color: colors.primary,
                  },
                }}
              />
            }
            label="Remember me"
          />
          <Link to={resetPasswordLink}>Recover Password</Link>
        </div>
      </div>
      <LoadingButton loading={isLoading} onClick={onSubmit} variant="contained" className="button">
        Login
      </LoadingButton>
    </FormGroup>
  );
}

LoginForm.defaultProps = {
  resetPasswordLink: "",
};

export default LoginForm;

const styles = {
  container: css`
    .inputs-container {
      width: 100%;
      margin: 50px 0;
    }
    .text-input {
      margin-top: 20px;
      width: 100%;
    }
    .inputs-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    @media (max-width: 900px) {
    }
    @media (max-width: 470px) {
      .inputs-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }
    }
  `,
};
