function MeasurementsIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"} viewBox="0 0 23.533 17.55">
      <path
        d="M8.064 8.536c1.948 0 3.538-1.2 3.538-2.662s-1.584-2.663-3.538-2.663-3.538 1.2-3.538 2.663 1.589 2.662 3.538 2.662zm0-4.379c1.419 0 2.592.785 2.592 1.717S9.469 7.591 8.064 7.591s-2.592-.785-2.592-1.717 1.187-1.721 2.592-1.721zm0 0"
        data-name="Path 2026"
      ></path>
      <path
        d="M23.06 10.793h-6.98V5.869C16.08 2.634 12.48 0 8.04 0S0 2.635 0 5.869v5.817c0 2.979 3.05 5.439 6.98 5.812h.08c.326.028.657.052.993.052H23.06a.473.473 0 00.473-.473v-5.811a.473.473 0 00-.473-.473zM8.06.951c3.9 0 7.094 2.209 7.094 4.923s-3.173 4.923-7.094 4.923S.97 8.589.97 5.874 4.167.951 8.064.951zm7.094 7.68v2.161h-2.74a7.131 7.131 0 002.719-2.161zm7.43 7.974h-.946v-2.393a.473.473 0 10-.946 0v2.4H18.8v-1.3a.473.473 0 00-.946 0v1.3h-1.888v-1.3a.473.473 0 10-.946 0v1.3h-1.892v-2.4a.473.473 0 10-.946 0v2.4h-1.811v-1.3a.473.473 0 00-.946 0v1.3H7.534v-1.3a.473.473 0 10-.946 0v1.187C3.4 16.023.993 14.045.993 11.682V8.631a8.776 8.776 0 007.094 3.107h14.5zm0 0"
        data-name="Path 2027"
      ></path>
    </svg>
  );
}

export default MeasurementsIcon;
