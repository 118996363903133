import { useState } from "react";
import { css } from "@emotion/css";
import Grid from "@mui/material/Grid";
import PlayArrowIcon from "../icons/playArrowIcon";
import Modal from "@mui/material/Modal";
import colors from "../constants/colors";

function Video() {
  const [open, setOpen] = useState(false);

  function openModal(e) {
    setOpen(!open);
  }

  return (
    <div className={styles.container} id="app">
      <Modal open={open} onClose={openModal} aria-labelledby="app-video" aria-describedby="spoguide-phone-app-video">
        <div className={styles.modalContent}>
          <video width="100%" height="auto" controls muted autoPlay>
            <source src="/videos/spoguide-promo.mp4" type="video/mp4" />
          </video>
        </div>
      </Modal>
      <Grid container>
        <Grid item xs={12}>
          <div className="theme-video-wrap">
            <span onClick={openModal} style={{ cursor: "pointer" }} className="video-btn" data-popup="video">
              <PlayArrowIcon size={50} color={colors.secondary} />
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Video;

const styles = {
  container: css`
    background-image: url(/img/video-bg.jpg);
    background-size: cover;
    background-position: 0% 78%;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 50px;
    :before {
      content: "";
      background: #5b39c9;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0.8;
    }
    .theme-video-wrap {
      padding-top: 325px;
      padding-bottom: 325px;
    }
    .video-btn {
      height: 125px;
      width: 125px;
      background: #fff;
      line-height: 125px;
      text-align: center;
      position: absolute;
      top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-radius: 100%;
      font-size: 30px;
      padding: 5px;
      svg {
        margin-left: 13px;
      }
    }
    .video-btn:before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: -1;
      background: #fff;
      left: 0;
      border-radius: 50%;
      -webkit-animation: scale 1s linear infinite;
      animation: scale 1s linear infinite;
      opacity: 0.9;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    /* @media (max-width: 991.98px) {
      .video-btn {
        height: 100px;
        width: 100px;
        line-height: 100px;
      }
    } */
  `,
  modalContent: css`
    outline: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
};
