import * as React from "react";
import { css } from "@emotion/css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import NavLinks from "./navLinks";

function MobileMenu(props) {
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={props.onClickAway}
      onKeyDown={props.onClickAway}
    >
      <div className={styles.navLinksContainer}>
        <NavLinks onClickLink={props.onClickAway} />
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer anchor="right" open={props.isOpen} onClose={props.onClickAway}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default MobileMenu;

const styles = {
  container: css`
    @media screen and (min-width: 900px) {
      display: none;
    }
  `,
  navLinksContainer: css`
    padding: 56px 20px;
    text-align: center;
  `,
};
