function ExerciseVideoIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"} viewBox="0 0 512 512">
      <path d="M304 299.43l128.54-122.328a24.002 24.002 0 00.427-34.356l-12.404-12.403a8 8 0 00-11.314 0l-5.364 5.365a17.535 17.535 0 00-30.805 3.318l-12.764 31.907-57.433 42.79a30.635 30.635 0 00-42.955-27.13 42.467 42.467 0 0131.787-18.47l16.236 16.235a18.397 18.397 0 0030.16-19.66l15.326-5.109a8 8 0 005.47-7.589v-16a24.027 24.027 0 00-24-24h-42.812a141.19 141.19 0 00-141.189 141.188V360a8 8 0 008 8H296a8 8 0 008-8zm67.686-117.015a7.996 7.996 0 002.648-3.444l13.602-34.002a1.54 1.54 0 012.97.571V152a8 8 0 0013.657 5.657l10.343-10.344 6.746 6.747a8 8 0 01-.141 11.451l-97.24 92.54-23.1-23.1zM243.19 223.029l12.392-12.391a61.96 61.96 0 0012.378 28.993l-4.084 4.085a14.627 14.627 0 11-20.686-20.687zm43.715-8.402a14.532 14.532 0 01-4.284 10.344l-3.225 3.225a46.278 46.278 0 01-8.49-26.823v-1.306c.453-.042.91-.067 1.372-.067a14.644 14.644 0 0114.627 14.627zM292.095 128h42.811a8.01 8.01 0 018 8v10.234l-18.53 6.177a8 8 0 00-4.625 11.167l3.352 6.701a2.395 2.395 0 01-1.073 3.215 2.39 2.39 0 01-2.764-.45l-18.702-18.7a8 8 0 00-5.658-2.344 58.472 58.472 0 00-52.788 33.61l-2.748 5.885-43.267-18.543A125.388 125.388 0 01292.095 128zm-1.61 162.205A7.999 7.999 0 00288 296v56H166.906v-98.812a124.743 124.743 0 0119.488-66.99L232.597 206l-5.807 12.437a30.63 30.63 0 0048.401 36.592l2.832-2.831 10.181-7.586 24.473 24.473z"></path>
      <path d="M472 40H40a24.027 24.027 0 00-24 24v384a24.027 24.027 0 0024 24h432a24.027 24.027 0 0024-24V64a24.027 24.027 0 00-24-24zm8 408a8.01 8.01 0 01-8 8H40a8.01 8.01 0 01-8-8V64a8.01 8.01 0 018-8h432a8.01 8.01 0 018 8z"></path>
      <path d="M48 408h16v32H48zM72 408h16v32H72zM256 400a24.038 24.038 0 00-22.624 16H104v16h129.376A23.998 23.998 0 10256 400zm0 32a8 8 0 118-8 8.01 8.01 0 01-8 8zM304 416h144v16H304z"></path>
      <circle cx="56" cy="80" r="8"></circle>
      <circle cx="80" cy="80" r="8"></circle>
      <circle cx="104" cy="80" r="8"></circle>
    </svg>
  );
}

export default ExerciseVideoIcon;
