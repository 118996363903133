import React from "react";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";

import TimelineIcon from "../icons/timelineIcon";
import Button from "../common/button";
import colors from "../constants/colors";

function CtaOne() {
  return (
    <Grid container className={styles.container} justifyContent="space-evenly" alignItems="center">
      <Grid item lg={5} md={6} sm={7}>
        <div className="user-interact-image">
          <img src="/img/feature/plansTimeline.png" alt="" />
        </div>
      </Grid>
      <Grid item lg={5} md={6} sm={5}>
        <div className="user-interact-inner">
          <div className="interact-icon">
            <TimelineIcon size={100} color={colors.primary} />
          </div>
          <h2>Timeline Planner For Your Goal</h2>
          <p>
            Workout plans timeline will guide you step by step by tracking your progress to give you the next proper plan. this timeline will stick
            with you the whole journey until you achieve your goal.
          </p>
          <Button text="Get Started" className={styles.button} />
        </div>
      </Grid>
    </Grid>
  );
}

export default CtaOne;

const styles = {
  container: css`
    background: #f7f6f9;
    padding: 120px 0;
    overflow: hidden;
    .user-interact-inner {
      padding: 30px;
    }
    .user-interact-inner .interact-icon {
      width: 65px;
      height: 65px;
      margin-bottom: 39px;
      @media screen and (max-width: 600px) {
        margin: 0 auto;
      }
    }
    .user-interact-inner h2 {
      color: #36324a;
      font-weight: 600;
      line-height: 54px;
      margin-bottom: 29px;
    }
    .user-interact-inner p {
      margin-bottom: 40px;
    }
    .user-interact-image {
      margin-left: -130px;
      @media screen and (max-width: 900px) {
        img {
          width: 700px;
          height: auto;
        }
      }
      @media screen and (max-width: 600px) {
        margin-left: 0px;
      }
    }
  `,
  button: css`
    width: 50px;
    align-self: center;
    @media screen and (max-width: 600px) {
      margin: 0 auto;
    }
  `,
};
