import axios from "axios";
import { getJwt } from "./authService";
import { logout } from "./authService";

axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "https://api.spoguide.com/api/v1" : "http://localhost:5000/api/v1";
axios.defaults.headers.common["Authorization"] = `Bearer ${getJwt()}`;
axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
  if (!expectedError) {
    console.log(error);
    alert("An unexpected error occurred");
  } else {
    return Promise.reject(error);
  }
});

export function getErr(error) {
  if (error.response) {
    alert(error.response.data.message);
  }
}

const httpService = {
  get: axios.get,
  post: axios.post,
  patch: axios.patch,
  delete: axios.delete,
};

export default httpService;
