import React from "react";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import TwitterIcon from "../icons/twitterIcon";
import EnvelopeIcon from "../icons/envelopeIcon";
import MasterCardIcon from "../icons/masterCardIcon";
import VisaIcon from "../icons/visaIcon";
import MapIcon from "../icons/mapIcon";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className={styles.footerContainer}>
        <div className="footerbg">
          <img src="/img/footer-bg.png" className="svg" alt="spoguide footer" />
        </div>

        <Grid container alignItems="center" justifyContent="center" className="grid-container">
          <Grid item xs={6} sm={12} md={3} lg={3}>
            <div className="footer-widget">
              <div className="footer-logo">
                <a href="https://spoguide.com/">
                  <img src="/img/logo.png" alt="spoguide" />
                </a>
              </div>
              <p>
                Spoguide is a body transformation specialized app, there are a high skills trainers
                setting behind, with professional experience.
              </p>
              <p className="freelancing-cer">
                This website is under freelancing certificate: f84f71a0
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={6} sm={6}>
                <div className="footer-widget">
                  <div className="widget-header">
                    <h5>Our Contact info</h5>
                  </div>

                  <div className="widget-body">
                    <ul className="address-list">
                      <li>
                        <EnvelopeIcon size={20} color={colors.mainFont} />
                        info@spoguide.com
                      </li>
                      <li>
                        <span>
                          <MapIcon size={20} color={colors.mainFont} />
                        </span>
                        Saudi Arabia, Riyadh
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} sm={6}>
                <div className="footer-widget">
                  <div className="widget-header">
                    <h5>Extra Links</h5>
                  </div>
                </div>
                <div className="widget-body">
                  <div className="extra-link">
                    <div className="link-left">
                      <ul>
                        <li>
                          <Link to="/privacyPolicy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/termsAndConditions">Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to="/refundPolicy">Refund Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={6} lg={3}>
            <div className="footer-widget">
              <div className="widget-body">
                <div className="twitter-post-inner">
                  <div className="footer-post-details">
                    @spoguide Turn your body into new shape.
                    <br />
                    <a href="https://twitter.com/spoguide">follow us on twitter</a>
                  </div>
                  <div className="twitter-post">
                    <TwitterIcon size={30} color={colors.primary} />
                    Spoguide - Aug 11, 2016
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="payments-logos-container">
          <MasterCardIcon size={60} />
          <VisaIcon size={60} />
        </div>
      </footer>
      <div className={styles.copyrights}>
        <p>© copyright 2022 by spoguide.com</p>
      </div>
    </div>
  );
}

export default Footer;

const styles = {
  footerContainer: css`
    display: flex;
    min-height: 518px;
    flex-direction: column;
    .grid-container {
      padding-top: 120px;
    }
    .footerbg {
      position: absolute;
      z-index: -1;
    }
    .footerbg {
      width: 100%;
    }
    .footerbg img {
      width: 100%;
      height: 518px;
    }
    .footer-logo img {
      width: 100%;
    }
    .footer-post-details a {
      color: ${colors.primary} !important;
    }
    .footer-post-details:after {
      height: 31px;
      width: 69px;
      background: #fff;
      content: "";
      position: absolute;
      bottom: -3px;
      left: 38px;
      -webkit-transform: rotate(-34deg);
      transform: rotate(-34deg);
    }

    .footer-post-details:before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      bottom: -20px;
      background: #f7f6f9;
      z-index: 1;
      left: 33px;
    }
    .footer-post-details {
      padding: 32px 20px 36px 40px;
      position: relative;
      background: #fff;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 20px;
      border-radius: 10px;
    }
    .twitter-post {
      padding-left: 20px;
      display: flex;
    }
    .widget-body ul {
      padding: 0;
    }
    .widget-body li {
      list-style: none;
    }
    .widget-body a {
      color: #6f6c7f;
      :hover {
        color: ${colors.primary};
      }
    }
    .address-list li {
      /* display: flex; */
      align-items: center;
    }
    .address-list li svg {
      margin-right: 10px;
    }
    .address-list li:hover {
      svg {
        fill: ${colors.primary};
      }
    }
    .payments-logos-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .freelancing-cer {
      color: ${colors.secondary};
      font-size: 12px;
    }
    @media (max-width: 575.98px) {
      .footer-post-details {
        margin-top: 30px;
      }
    }
    @media (max-width: 991px) {
      padding: 30px 20px;
      position: relative;
      background: #f7f6f9;
      text-align: center;
      .footerbg {
        display: none;
      }
      .address-list li svg {
        margin-left: 20px;
      }
    }
  `,
  copyrights: css`
    width: 100%;
    text-align: center;
    height: 100px;
    P {
      line-height: 100px;
    }
  `,
};
