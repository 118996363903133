import { useState } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import TextInput from "../common/TextInput";
import httpService, { getErr } from "../auth/httpServices";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import FormGroup from "@mui/material/FormGroup";
import { checkValidation } from "../common/checkValidation";
import config from "../config.json";
import NavigateBackButton from "../common/navigateBackButton";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputError] = useState({
    email: { isValid: true, errorMessage: "" },
  });

  let navigate = useNavigate();

  function handleChange(e) {
    setEmail(e.target.value);
    setInputError({ email: { isValid: true, errorMessage: "" } });
  }

  function handleSend(e) {
    setIsLoading(true);
    checkValidation({ email }, setInputError)
      .then(() => {
        httpService
          .post(config.clientForgotPasswordEndPoint, { email })
          .then((results) => {
            setIsLoading(false);
            navigate("email-code", { state: { email } });
          })
          .catch((error) => {
            setIsLoading(false);
            getErr(error);
          });
      })
      .catch(() => {
        setIsLoading(false);
        return;
      });
  }

  return (
    <div className={styles.container}>
      <NavigateBackButton containerClassName="back-container" text="Back to login" />
      <h4>Reset Your Password</h4>
      <p>Enter the email associated with your account and we'll send an email with instructions to reset your password.</p>
      <FormGroup>
        <div className="inputs-container">
          <TextInput
            error={!inputErrors.email.isValid}
            helperText={inputErrors.email.errorMessage}
            className="text-input"
            onChange={handleChange}
            value={email}
            type="email"
            label="Email"
            autoComplete="email"
          />
        </div>
        <LoadingButton loading={isLoading} onClick={handleSend} variant="contained" className="button">
          Send
        </LoadingButton>
      </FormGroup>
    </div>
  );
}

export default ResetPassword;

const styles = {
  container: css`
    .back-container {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px;
    }
    h4 {
      line-height: 50px;
    }
    .inputs-container {
      width: 100%;
      margin-top: 20px;
    }
    .text-input {
      margin-top: 20px;
      width: 100%;
    }
    .button {
      height: 50px;
      background: ${colors.primary};
      border-radius: 15px;
      margin: 20px 0;
    }
    .button:hover {
      background: ${colors.primary};
      opacity: 0.9;
    }
  `,
};
