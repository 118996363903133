import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import GooglePlayIcon from "../icons/googlePlayIcon";
import AppStoreIcon from "../icons/appStoreIcon";
import Button from "../common/button";

function Banner() {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrap}>
        <img src="/img/banner/main-base.png" alt="" className={styles.bannerBackground} />
        <Grid container direction="row" alignItems="center" justifyContent="space-evenly">
          <Grid item md={5} lg={5} xl={6}>
            <div className={styles.bannerTextInner}>
              <div className={styles.bannerShapeInner}>
                <img src="/img/banner/shaps1.png" alt="" className="shape shape1 rotate3d" />
                <img src="/img/banner/shaps2.png" alt="" className="shape shape2 rotate2d" />
                <img src="/img/banner/shaps3.png" alt="" className="shape shape3 rotate-2d" />
                <img src="/img/banner/shaps4.png" alt="" className="shape shape4 rotate3d" />
                <img src="/img/banner/shaps5.png" alt="" className="shape shape5 rotate2d" />
                <img src="/img/banner/shaps6.png" alt="" className="shape shape6 rotate-2d" />
                <img src="/img/banner/shaps7.png" alt="" className="shape shape7 rotate3d" />
              </div>

              <h1>Spoguide is your GYM personal trainer</h1>
              <p>
                Spoguide is not only your personal trainer, it's your workout plan guide. Spoguide will build the right workout plans for you and help
                you to achieve your body shape dream in a better and consistent way
              </p>
              <div className="download-buttons">
                <Button text="Download App" href="#" className="button" rightIcon={<GooglePlayIcon size={30} />} />
                <Button text="Download App" href="#" className="button" rightIcon={<AppStoreIcon size={30} />} />
              </div>
            </div>
          </Grid>
          <Grid item md={5} lg={5} xl={3}>
            <div className={styles.bannerImage}>
              <img src="/img/banner/mockup.png" alt="" />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Banner;

const styles = {
  bannerContainer: css``,
  bannerWrap: css`
    height: 100%;
    width: 100%;
    padding: 150px 0;
    position: relative;
    overflow: hidden;
  `,
  bannerBackground: css`
    position: absolute;
    z-index: 0;
    top: 0;
    right: -240px;
    @media screen and (max-width: 1200px) {
      right: -340px;
      top: -20%;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  `,
  bannerTextInner: css`
    h1 {
      color: #36324a;
      margin-bottom: 33px;
      font-weight: 500;
    }
    p {
      margin-bottom: 43px;
    }
    .download-buttons {
      display: flex;
      .button {
        margin: 0 10px;
        margin-bottom: 50px;
      }
    }
    position: relative;
    z-index: 1;
    padding: 0 50px;
  `,
  bannerShapeWrap: css``,
  bannerShapeInner: css`
    @-webkit-keyframes rotate2d {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotate2d {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .rotate2d {
      -webkit-animation: rotate2d 2s linear infinite;
      animation: rotate2d 1s linear infinite;
    }
    @-webkit-keyframes rotate-2d {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
      }
    }
    @keyframes rotate-2d {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
      }
    }
    .rotate-2d {
      -webkit-animation: rotate-2d 2s linear infinite;
      animation: rotate-2d 1s linear infinite;
    }
    @-webkit-keyframes rotate3d {
      0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }
      100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
      }
    }

    @keyframes rotate3d {
      0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }
      100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
      }
    }
    .rotate3d {
      -webkit-animation: rotate3d 2s linear infinite;
      animation: rotate3d 1s linear infinite;
    }
    .shape {
      position: absolute;
      z-index: -1;
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
    }
    .shape1 {
      top: -34%;
      left: -12%;
    }

    .shape2 {
      top: -24%;
      left: 37%;
    }

    .shape3 {
      top: -34%;
      left: 114%;
    }

    .shape4 {
      top: -5%;
      right: 13%;
    }

    .shape5 {
      top: 43%;
      left: 49%;
    }

    .shape6 {
      top: 53%;
      left: -12%;
    }

    .shape7 {
      top: 103%;
      right: -6%;
    }
  `,
  bannerImage: css`
    position: relative;
    z-index: 1;
    @keyframes bounce {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
      }
    }
    -webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
    animation: bounce 1s ease-in-out 0s infinite alternate;
  `,
};
