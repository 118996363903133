import { useContext } from "react";
import { css } from "@emotion/css";
import Grid from "@mui/material/Grid";
import colors from "../constants/colors";
import SectionTitle from "./SectionTitle";
import Card from "../common/card";
import CheckMarkIcon from "../icons/checkMarkIcon";
import Button from "../common/button";
import { PricingsContext } from "../context/pricingsContext";

function Pricings({ onClick }) {
  const { pricings } = useContext(PricingsContext);

  const sortedPrices = pricings ? pricings.sort((a, b) => a.id - b.id) : [];

  return (
    <div className={styles.container} id="pricing">
      <SectionTitle
        title="Pricing Plans"
        text="Our pricing plans are the lowest price ever compared to the features. You won't find a better price than ours"
      />
      <Grid container alignItems="center" justifyContent="center">
        {sortedPrices.map((pricing) => (
          <Grid item key={pricing.id}>
            <Card className={`card ${pricing.duration === 365 ? "active" : ""}`} isActive={pricing.duration === 365 ? true : false}>
              <div className="tag-wrapper">
                <div className={pricing.duration === 365 ? "tag" : ""}>{pricing.duration === 365 && <p>Most Popular</p>}</div>
              </div>
              <div className="top-price-card">
                <h4>{pricing.name}</h4>
                <span>{pricing.currencySymbol + String(pricing.price)}</span>
                <p>{pricing.description}</p>
              </div>
              <div className="single-price-body">
                <div className="price-features">
                  <ul>
                    <li>
                      <span>
                        <CheckMarkIcon size={20} color={colors.success} />
                      </span>
                      Workout plans
                    </li>
                    <li>
                      <span>
                        <CheckMarkIcon size={20} color={colors.success} />
                      </span>
                      Exercises history tracking
                    </li>
                    <li>
                      <span>
                        <CheckMarkIcon size={20} color={colors.success} />
                      </span>
                      Measurements Compare Tool
                    </li>
                  </ul>
                </div>
                <Button onClick={onClick} text="Get Started" className="pricing-button" id={pricing.id} />
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Pricings;

const styles = {
  container: css`
    padding: 20px;
    .MuiGrid-item {
      margin: 20px 30px;
    }
    .card {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 50px 10px;
      width: 100%;
    }
    .active {
      padding: 80px 6px;
    }
    .tag-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 15px;
      overflow: hidden;
    }
    .tag {
      height: 200px;
      width: 200px;
      background: ${colors.primary};
      position: absolute;
      top: -102px;
      left: -102px;
      transform: rotate(45deg);
      p {
        transform: rotate(270deg);
        position: absolute;
        margin: 0;
        bottom: 86px;
        right: -32px;
        color: #fff;
      }
    }
    .pricing-button {
      background: #fff0;
      margin-top: 50px;
    }
    .card .top-price-card {
      h4 {
        color: #36324a;
        margin-bottom: 25px;
      }
      span {
        font-size: 60px;
        font-weight: 200;
        color: #3f51b5;
        display: block;
        line-height: 40px;
      }
      p {
        line-height: 30px;
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 16px;
        margin: 0;
      }
    }
    .price-features {
      ul {
        padding: 0;
      }
      li {
        list-style: none;
      }
    }
  `,
};
