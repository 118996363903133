import React, { createContext, useState, useEffect, useContext } from "react";
import httpServices, { getErr } from "../auth/httpServices";
import config from "../config.json";
import { UserContext } from "../context/userContext";
import { css } from "@emotion/css";
import Card from "../common/card";
import SpinnerIcon from "../icons/spinner";
import colors from "../constants/colors";

const PricingsContext = createContext();

function PricingsProvider({ children }) {
  const { currentUser } = useContext(UserContext);
  const [pricings, setPricings] = useState([]);

  const filteredPricings = pricings.filter((pricing) =>
    currentUser.subscriptions && currentUser.subscriptions.length > 0
      ? +pricing.price !== 0
      : pricing
  );

  useEffect(() => {
    httpServices
      .get(config.pricingsEndPoint)
      .then((results) => {
        if (Array.isArray(results.data)) {
          setPricings(results.data);
        } else {
          setPricings([]);
        }
      })
      .catch((error) => {
        setPricings([]);
        getErr(error);
      });
  }, []);

  return (
    <PricingsContext.Provider value={{ pricings, userPricings: filteredPricings }}>
      {pricings.length > 0 ? (
        children
      ) : (
        <div className={styles.container}>
          <Card className="card">
            <SpinnerIcon color={colors.primary} size="100%" />
          </Card>
        </div>
      )}
    </PricingsContext.Provider>
  );
}

export { PricingsContext, PricingsProvider };

const styles = {
  container: css`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #00000042;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      min-height: 0;
      min-width: 0;
      width: 10%;
      --box-shadow-color: #00000039;
      display: flex;
      padding: 30px;
    }
  `,
};
