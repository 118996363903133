import React, { createContext, useState, useEffect } from "react";
import { getCurrentUser, logout } from "../auth/authService";
import httpServices, { getErr } from "../auth/httpServices";
import config from "../config.json";

const UserContext = createContext();
const user = getCurrentUser();

function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (user) {
      httpServices
        .get(config.clientEndPoint)
        .then((results) => {
          setCurrentUser(results.data);
        })
        .catch((error) => {
          setCurrentUser(null);
          getErr(error);
          logout();
          window.location.reload();
        });
    } else {
      setCurrentUser({});
    }
  }, []);

  return currentUser && <UserContext.Provider value={{ currentUser }}>{children}</UserContext.Provider>;
}

export { UserContext, UserProvider };
