import { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { css } from "@emotion/css";
import colors from "../../constants/colors";
import httpService, { getErr } from "../../auth/httpServices";
import config from "../../config.json";
import FailureIcon from "../../icons/failureIcon";
import LoadingButton from "../../common/LoadingButton";

const paymentStarted = JSON.parse(localStorage.getItem("paymentStarted"));
const localSelectedPricing = JSON.parse(localStorage.getItem("selectedPricing"));

function PaymentProcessing({ planId: selectedPlanId, onCompleteTransaction, isRedirected }) {
  const redirectFormRef = useRef();
  const [planId, setPlanId] = useState(selectedPlanId);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isPaymentFail, setIsPaymentFail] = useState(false);

  useEffect(() => {
    setIsFormLoading(true);
    setPlanId(selectedPlanId);
    if (isRedirected) {
      httpService
        .post(config.placeNewSubscriptionOrderEndPoint, {
          pricingId: localSelectedPricing.id,
          channel: "website",
          currency: "SAR",
        })
        .then((results) => {
          localStorage.setItem(
            "paymentStarted",
            JSON.stringify({ orderId: results.data.order.id, planId })
          );
          return results;
        })
        .then((results) => {
          window.location.href = results.data.order.payment_url;
        })
        .catch((error) => {
          getErr(error);
        });
    } else if (paymentStarted) {
      httpService
        .post(config.checkSubscriptionOrderEndPoint, {
          orderId: paymentStarted.orderId,
        })
        .then((results) => {
          if (results.data.finished) {
            localStorage.removeItem("paymentStarted");
            onCompleteTransaction();
          } else {
            setIsFormLoading(false);
            setIsPaymentFail(true);
            localStorage.removeItem("paymentStarted");
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          setIsPaymentFail(true);
          localStorage.removeItem("paymentStarted");
        });
    }
  }, [isRedirected, selectedPlanId]);

  function handleTryAgain() {
    setIsPaymentFail(false);
    setIsFormLoading(true);
    httpService
      .post(config.placeNewSubscriptionOrderEndPoint, {
        pricingId: planId,
        channel: "website",
        currency: "SAR",
      })
      .then((results) => {
        localStorage.setItem(
          "paymentStarted",
          JSON.stringify({ orderId: results.data.order.id, planId })
        );
        return results;
      })
      .then((results) => {
        window.location.href = results.data.order.payment_url;
      })
      .catch((error) => {
        getErr(error);
      });
  }

  return (
    <div className={styles.container}>
      {isFormLoading && (
        <div className="form-loading">
          <CircularProgress
            sx={{
              color: colors.primary,
            }}
          />
        </div>
      )}
      {isPaymentFail && (
        <div className="payment-fail">
          <FailureIcon size={80} color={colors.warning} />
          <h4>Your payment failed</h4>
          <LoadingButton onClick={handleTryAgain}>Try again</LoadingButton>
        </div>
      )}
      <iframe
        ref={redirectFormRef}
        title="Card Auth"
        id="auth-iframe"
        className="auth-iframe"
      ></iframe>
    </div>
  );
}

export default PaymentProcessing;

const styles = {
  container: css`
    position: relative;
    min-height: 400px;
    .payment-container {
      min-height: 200px;
      background: #fff;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: none;
    }
    .form-loading {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #ffffffb2;
      z-index: 2;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .payment-fail {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #fff;
      z-index: 1;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .payment-fail h4 {
      margin: 20px;
      color: ${colors.warning};
    }
    .auth-iframe {
      border: 0;
      border-radius: 15px;
      height: 500px;
      width: 100%;
    }
  `,
};
