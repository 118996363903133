import React from "react";

function EnvelopeIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "512"}
      height={size || "512"}
      x="0"
      y="0"
      enableBackground="new 0 0 469.2 469.2"
      version="1.1"
      viewBox="0 0 469.2 469.2"
      xmlSpace="preserve"
      fill={color || "#000"}
    >
      <path d="M22.202 77.023C25.888 75.657 29.832 74.8 34 74.8h401.2c4.168 0 8.112.857 11.798 2.224L267.24 246.364c-18.299 17.251-46.975 17.251-65.28 0L22.202 77.023zm441.986 300.921c3.114-5.135 5.012-11.098 5.012-17.544V108.8c0-4.569-.932-8.915-2.57-12.899L298.411 254.367l165.777 123.577zM283.2 268.464c-13.961 11.961-31.253 18.027-48.6 18.027s-34.64-6.06-48.6-18.027L20.692 391.687A33.897 33.897 0 0034 394.401h401.2c4.726 0 9.214-.973 13.308-2.714L283.2 268.464zM2.571 95.9A33.813 33.813 0 000 108.8v251.6c0 6.446 1.897 12.409 5.012 17.544l165.777-123.577L2.571 95.9z"></path>
    </svg>
  );
}

export default EnvelopeIcon;
