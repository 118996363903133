import { useEffect, useState } from "react";
import NavBar from "../components/navBar";
import MobileMenu from "../components/MobileMenu";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import RadioButton from "../common/radioButton";
import SwitchContent from "../common/switchContent";
import AvatarIcon from "../icons/avatarIcon";
import RecurringSubscriptionIcon from "../icons/recurringSubscriptionIcon";
import LockIcon from "../icons/lockIcon";
import LogoutIcon from "../icons/logoutIcon";
import MyAccount from "../components/myAccount";
import Button from "@mui/material/Button";
import { logout } from "../auth/authService";
import MySubscription from "../components/mySubscription";
import ChangePassword from "../components/changePassword";

const screenWidth = window.screen.width;

const tabs = [
  { id: 1, name: "account", icon: AvatarIcon, component: MyAccount },
  { id: 2, name: "subscription", icon: RecurringSubscriptionIcon, component: MySubscription },
  { id: 3, name: "password", icon: LockIcon, component: ChangePassword },
];

function Account() {
  const [activeTab, setActiveTab] = useState(() => {
    const localTab = JSON.parse(localStorage.getItem("activeAccountTab"));
    return localTab || 1;
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("activeAccountTab", JSON.stringify(activeTab));
  }, [activeTab]);

  function handleMobileMenuToggle() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  function handleRadioButtonClick(e) {
    setActiveTab(+e.currentTarget.id);
  }

  function handleLogout() {
    logout();
    window.location.replace("/");
  }

  return (
    <div>
      <MobileMenu isOpen={isMobileMenuOpen} onClickAway={handleMobileMenuToggle} />
      <NavBar secondaryPage onOpen={handleMobileMenuToggle} />
      <div className={styles.container}>
        <div className="logout-button-wrapper">
          <Button onClick={handleLogout} endIcon={<LogoutIcon size={30} color={colors.secondary} />} className="text-button" variant="text">
            Logout
          </Button>
        </div>
        <Grid className="grid-container" container alignItems="center">
          <Grid item xs={12} sm={12} md={3}>
            <div className="tabs-buttons-wrapper">
              {tabs.map((tab) => (
                <RadioButton
                  className={`radio-button ${tab.id === activeTab ? "active" : ""}`}
                  activeColor={colors.primary}
                  onClick={handleRadioButtonClick}
                  key={tab.id}
                  name={tab.name}
                  id={tab.id}
                  active={tab.id === activeTab}
                >
                  <tab.icon size={80} color={tab.id === activeTab ? "#fff" : colors.primary} />
                  <p>{tab.name}</p>
                </RadioButton>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <SwitchContent activeIndex={activeTab - 1} direction={screenWidth < 900 ? "x" : "y"} className="switch-container">
              {tabs.map((tab) => (
                <div key={tab.id} className="content">
                  <tab.component />
                </div>
              ))}
            </SwitchContent>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Account;

const styles = {
  container: css`
    position: absolute;
    background: #eee;
    min-height: 100%;
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
    overflow: hidden;
    .logout-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .text-button {
      color: ${colors.secondary};
    }
    .grid-container {
      height: 100%;
    }
    .radio-button {
      width: 120px;
      height: 120px;
      margin: 20px 0;
    }
    .radio-button p {
      margin: 0;
      margin-top: 10px;
      color: ${colors.primary};
    }
    .radio-button.active p {
      color: #fff;
    }
    .switch-container {
      border-radius: 15px;
    }
    .content {
      width: 100%;
      background: #fff;
      border-radius: 15px;
    }
    .tabs-buttons-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    @media (max-width: 900px) {
      .radio-button {
        width: 80px;
        height: 80px;
        margin: 10px;
      }
      .tabs-buttons-wrapper {
        margin-top: 80px;
        margin-bottom: 20px;
      }
    }
    @media (max-width: 600px) {
      padding: 7px;
      .radio-button {
        width: 50px;
        height: 50px;
        margin: 10px;
      }
      .radio-button p {
        margin: 0;
        margin-top: 0px;
        color: ${colors.primary};
      }
      .tabs-buttons-wrapper {
        margin-top: 100px;
        margin-bottom: 20px;
      }
    }
  `,
};
