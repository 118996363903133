import React from "react";

function MapIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 493.149 493.149"
      version="1.1"
      viewBox="0 0 493.149 493.149"
      xmlSpace="preserve"
      width={size || "512"}
      height={size || "512"}
      fill={color || "#000"}
    >
      <g>
        <path d="M134.637 59.363L6.133 113.511A10.028 10.028 0 000 122.749v303.226a10.03 10.03 0 004.478 8.347 10.023 10.023 0 009.438.883L142.42 381.05a10.027 10.027 0 006.133-9.238V68.594c0-3.354-1.686-6.493-4.479-8.348a10.007 10.007 0 00-9.437-.883z"></path>
        <path d="M313.852 98.236L185.34 57.622a10.02 10.02 0 00-13.042 9.551v303.956a10.02 10.02 0 006.997 9.56l128.514 40.607c3.042.963 6.365.417 8.94-1.469a10.015 10.015 0 004.103-8.082V107.796c0-4.374-2.835-8.243-7-9.56z"></path>
        <path d="M489.047 59.091a10.015 10.015 0 00-8.94-1.468L351.593 98.236a10.024 10.024 0 00-6.998 9.561v303.948a10.018 10.018 0 0013.043 9.551l128.511-40.607c4.166-1.316 7-5.185 7-9.56V67.173c0-3.194-1.524-6.196-4.102-8.082z"></path>
      </g>
    </svg>
  );
}

export default MapIcon;
