import { css } from "@emotion/css";
import colors from "../constants/colors";

function FeatureCard({ title, text, icon, status, moveStatus }) {
  return (
    <div className={`${styles.container} ${status.isActive && !moveStatus && styles.activeSlide}`}>
      {(status.isPrev || status.isNext) && <div className="inactive-slide" />}
      <div className="content-wrapper">
        <div className="feature-icon">{icon}</div>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default FeatureCard;

const styles = {
  container: css`
    text-align: center;
    padding: 74px 26px;
    width: 100%;
    @media screen and (max-width: 600px) {
      width: 70%;
    }
    .inactive-slide {
      position: absolute;
      background: #fff;
      z-index: 999;
      height: 100%;
      width: 100%;
      opacity: 0.5;
      @media screen and (max-width: 600px) {
        width: 70%;
      }
    }
    .content-wrapper {
      max-width: 350px;
      margin: 0 auto;
      .feature-icon svg rect,
      .feature-icon svg path {
        fill: #36324a;
        -webkit-transition: 0.3s all linear;
        transition: 0.3s all linear;
      }
      h5 {
        margin-bottom: 34px;
        color: #36324a;
        font-weight: 600;
        white-space: pre-line;
      }
      p {
        margin-bottom: 0;
        color: #6f6c7f;
        font-size: 16px;
        line-height: 30px;
      }
    }
  `,
  activeSlide: css`
    border: 4px solid ${colors.primary};
    border-radius: 15px;
    min-height: 300px;
    margin: 0 auto;
    padding: 70px 22px;
  `,
};
