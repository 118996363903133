import { useState } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import TextInput from "../common/TextInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import httpService, { getErr } from "../auth/httpServices";
import config from "../config.json";
import { checkValidation } from "../common/checkValidation";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import NavigateBackButton from "../common/navigateBackButton";

function NewPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputError] = useState({ password: { isValid: true, errorMessage: "" } });

  const navigate = useNavigate();
  const location = useLocation();

  function handleChange(e) {
    const { value } = e.target;
    setNewPassword(value);
    setInputError({ password: { isValid: true, errorMessage: "" } });
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleSave() {
    setIsLoading(true);
    checkValidation({ password: newPassword }, setInputError)
      .then(() => {
        httpService
          .patch(config.clientResetPasswordEndPoint, { newPassword }, { headers: { Authorization: "Bearer " + location.state.validateToken } })
          .then((results) => {
            setIsLoading(false);
            alert(results.data.message);
            navigate("/login", { replace: true });
          })
          .catch((error) => {
            setIsLoading(false);
            getErr(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        return;
      });
  }

  return location.state ? (
    <div className={styles.container}>
      <NavigateBackButton containerClassName="back-container" text="Back to login" to="/login" navigateOptions={{ replace: true }} />
      <h4>New password</h4>
      <p>Please set a new strong password.</p>
      <div className="input-container">
        <TextInput
          type={showPassword ? "text" : "password"}
          label="New Password"
          onChange={handleChange}
          name="newPassword"
          value={newPassword}
          error={!inputErrors.password.isValid}
          helperText={inputErrors.password.errorMessage}
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" name="newPassword" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <LoadingButton className="button" loading={isLoading} onClick={handleSave} variant="contained">
        Save
      </LoadingButton>
    </div>
  ) : (
    <Navigate to="/404" />
  );
}

export default NewPassword;

const styles = {
  container: css`
    .back-container {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px;
    }
    .input-container {
      width: 100%;
      margin-top: 20px;
    }
    .button {
      height: 50px;
      background: ${colors.primary};
      border-radius: 15px;
      margin: 20px 0;
    }
    .button:hover {
      background: ${colors.primary};
      opacity: 0.9;
    }
  `,
};
