import { css } from "@emotion/css";
import Button from "../common/button";
import Card from "../common/card";
import colors from "../constants/colors";
import CheckMarkIcon from "../icons/checkMarkIcon";

function PaymentConfirmation() {
  function handleClick() {
    console.log(window.isMobile);
    if (window.isMobile) {
      window.location.href = "http://app.spoguide.com/";
    } else {
      window.location.replace("/");
    }
  }

  return (
    <Card className={styles.container}>
      <div className="icon-container">
        <CheckMarkIcon size={50} color={colors.success} />
      </div>
      <h4>Thank you for your payment!</h4>
      <p>
        You can now enjoy with all Spoguide app features <span className="emoji">&#127881;</span>
      </p>
      <Button onClick={handleClick} text={window.isMobile ? "Get Spoguide app" : "Go to home"} className="button" />
    </Card>
  );
}

export default PaymentConfirmation;

const styles = {
  container: css`
    padding: 20px;
    background: #fff;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .icon-container {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px solid ${colors.success};
      border-radius: 50%;
    }
    h4 {
      margin: 10px 0;
    }
    p {
      margin: 0;
    }
    .emoji {
      font-size: 25px;
    }
    .button {
      margin: 20px 0;
      width: 50%;
    }
  `,
};
