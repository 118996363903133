function ChecklistIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"} viewBox="0 0 512.001 512.001">
      <path d="M400.003 152H256.001c-5.523 0-10 4.477-10 10s4.477 10 10 10h144.002c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-34.992 50.931c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zm-101.95-157c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07c1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zM315.878 200h-59.877c-5.523 0-10 4.477-10 10s4.477 10 10 10h59.877c5.523 0 10-4.477 10-10s-4.477-10-10-10zm84.125 60H256.001c-5.523 0-10 4.477-10 10s4.477 10 10 10h144.002c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-34.992 50.931c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zM315.878 308h-59.877c-5.523 0-10 4.477-10 10s4.477 10 10 10h59.877c5.523 0 10-4.477 10-10s-4.477-10-10-10zm84.125 60H256.001c-5.523 0-10 4.477-10 10s4.477 10 10 10h144.002c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-34.992 50.931c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93c1.86-1.86 2.93-4.44 2.93-7.07s-1.07-5.21-2.93-7.07zM315.878 416h-59.877c-5.523 0-10 4.477-10 10s4.477 10 10 10h59.877c5.523 0 10-4.477 10-10s-4.477-10-10-10z"></path>
      <path d="M419.243 39.001h-76.379C331.823 28.48 316.898 22 300.479 22h-8.76C285.022 8.742 271.263 0 256 0s-29.021 8.742-35.719 22H211.5c-16.419 0-31.343 6.48-42.384 17.001H92.759c-26.885 0-48.758 21.873-48.758 48.758v375.484c0 26.885 21.873 48.758 48.758 48.758h326.483c26.885 0 48.758-21.873 48.758-48.758V87.759c.001-26.885-21.872-48.758-48.757-48.758zM211.501 42h15.586a10 10 0 009.639-7.338C239.111 26.029 247.037 20 256.001 20c8.964 0 16.89 6.029 19.274 14.662A10 10 0 00284.914 42h15.565c21.705 0 39.571 16.75 41.354 38.001H170.147C171.93 58.75 189.797 42 211.501 42zm236.5 421.244c0 15.857-12.901 28.758-28.758 28.758H92.759c-15.857 0-28.758-12.901-28.758-28.758V87.759c0-15.857 12.901-28.758 28.758-28.758h62.347a61.135 61.135 0 00-5.105 24.5v6.5c0 5.523 4.477 10 10 10H351.98c5.523 0 10-4.477 10-10v-6.5a61.139 61.139 0 00-5.105-24.5h62.368c15.857 0 28.758 12.901 28.758 28.758v375.485z"></path>
      <path d="M192.41 149.596c-3.905-3.905-10.237-3.905-14.142-.001l-42.762 42.763-13.173-13.174c-3.905-3.904-10.237-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.143l20.245 20.245a9.968 9.968 0 007.071 2.929 9.966 9.966 0 007.071-2.929l49.833-49.833c3.905-3.905 3.905-10.237 0-14.143zM168.001 368h-48c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28v28zm10-156h-48c-5.523 0-10 4.477-10 10v48c0 5.523 4.477 10 10 10h48c5.523 0 10-4.477 10-10v-48c0-5.523-4.477-10-10-10zm-10 48h-28v-28h28v28z"></path>
    </svg>
  );
}

export default ChecklistIcon;
