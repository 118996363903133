import { css } from "@emotion/css";

function SectionTitle({ title, text }) {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
}

export default SectionTitle;

const styles = {
  container: css`
    text-align: center;
    h2 {
      font-size: 40px;
      color: #36324a;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      color: #6f6c7f;
      line-height: 30px;
    }
  `,
};
