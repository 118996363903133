function Layout(props) {
  return (
    <div>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{props.pageTitle}</title>
        <link rel="shortcut icon" type="image/png" href="/img/favicon.png" />
        <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700" rel="stylesheet" />
      </head>
      <div className="page-wrapper" id="wrapper">
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
