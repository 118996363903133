import React from "react";

function RecurringSubscriptionIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 122.88 109.93"
      version="1.1"
      viewBox="0 0 122.88 109.93"
      xmlSpace="preserve"
      width={size || "512"}
      height={size || "512"}
      fill={color || "#000"}
    >
      <path d="M115.47 65.19c-2.39 12.7-9.16 23.86-18.67 31.85-9.57 8.04-21.89 12.88-35.33 12.88A54.72 54.72 0 0127.9 98.47c-9.5-7.33-16.52-17.7-19.63-29.66l6.86-1.78c2.7 10.41 8.82 19.44 17.09 25.83 8.08 6.24 18.22 9.95 29.24 9.95 11.73 0 22.47-4.21 30.78-11.19 7.95-6.68 13.7-15.91 15.98-26.44h-7.33l10.99-13.39 10.99 13.39h-7.4v.01zm-54.18 3.88V56.52c-4.42-1.12-7.65-2.81-9.7-5.07-2.06-2.27-3.1-5.02-3.1-8.26 0-3.28 1.17-6.04 3.5-8.26 2.33-2.23 5.43-3.51 9.3-3.85v-2.95h4.94v2.95c3.62.38 6.49 1.47 8.64 3.26 2.13 1.79 3.5 4.19 4.09 7.19l-8.63.98c-.53-2.36-1.9-3.96-4.1-4.8v11.71c5.46 1.29 9.18 2.98 11.15 5.04 1.98 2.07 2.97 4.72 2.97 7.96 0 3.62-1.24 6.66-3.73 9.14-2.49 2.48-5.95 4.02-10.39 4.63v5.6h-4.94v-5.53c-3.9-.42-7.06-1.69-9.51-3.83s-4-5.17-4.68-9.08l8.83-.92c.36 1.6 1.04 2.97 2.04 4.13 1 1.16 2.1 1.99 3.32 2.51zm0-31.47c-1.33.42-2.38 1.11-3.15 2.07-.78.96-1.16 2.02-1.16 3.18 0 1.06.35 2.04 1.06 2.95.71.9 1.8 1.64 3.26 2.19V37.6h-.01zm4.94 31.97c1.7-.33 3.1-1.05 4.16-2.15C71.47 66.31 72 65.01 72 63.5c0-1.33-.45-2.49-1.36-3.45-.89-.97-2.36-1.71-4.42-2.23v11.75h.01zM7.46 44.74C9.83 32.15 16.5 21.08 25.87 13.1 35.47 4.93 47.9 0 61.46 0c11.93 0 22.97 3.8 31.98 10.26a55.103 55.103 0 0120.08 27.06l-3.36 1.14-3.36 1.14c-.09-.28-.19-.56-.29-.83-3.31-9.21-9.38-17.11-17.2-22.72a47.615 47.615 0 00-27.84-8.93c-11.84 0-22.67 4.28-31.01 11.38-7.84 6.67-13.49 15.82-15.76 26.24h7.29l-11 13.39L0 44.74h7.46z"></path>
    </svg>
  );
}

export default RecurringSubscriptionIcon;
