import { useEffect, useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import { css } from "@emotion/css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "../common/card";
import SwitchContent from "../common/switchContent";
import { useNavigate } from "react-router-dom";
import httpService, { getErr } from "../auth/httpServices";
import config from "../config.json";
import { getCurrentUser } from "../auth/authService";
import colors from "../constants/colors";
import ChoosePlan from "./choosePlan";
import StepperLogin from "./stepperLogin";
import StepperMoveButtons from "./stepperMoveButtons";
import { IconButton } from "@mui/material";
import CloseIcon from "../icons/closeIcon";
import PaymentProcessing from "../api/noon-payments/paymentProcessing";
import PaymentConfirmation from "./paymentConfirmation";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";

const user = getCurrentUser();

const steps = [
  { label: "Login", prevButton: false, nextButton: false },
  { label: "Choose a plan", prevButton: false, nextButton: true },
  { label: "Payment processing", prevButton: true, nextButton: false },
  { label: "Confirmation", prevButton: false, nextButton: false },
];

function PaymentStepper({ onClose, isOpen, defaultPricing }) {
  const [choosenPlan, setChoosenPlan] = useState(defaultPricing);
  const [activeIndex, setActiveIndex] = useState(user ? 1 : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);
  const navigate = useNavigate();
  const paymentStarted = JSON.parse(localStorage.getItem("paymentStarted"));

  useEffect(() => {
    if (paymentStarted) {
      setActiveIndex(2);
    }
  }, [paymentStarted]);

  function handleStepMove(e) {
    const { name } = e.currentTarget;
    if (name === "prev" && activeIndex > 0) {
      localStorage.removeItem("paymentStarted");
      setIsRedirected(false);
      setActiveIndex(activeIndex - 1);
    } else if (name === "next" && activeIndex === 1) {
      setIsLoading(true);
      if (+choosenPlan.price === 0) {
        const trialConfirm = window.confirm("Are you sure you want to start the free month?");
        if (trialConfirm) {
          httpService
            .post(config.subscriptionsEndPoint, {
              pricingId: choosenPlan.id,
              status: "Active",
              name: choosenPlan.name,
              description: choosenPlan.description,
              duration: choosenPlan.duration,
              price: choosenPlan.price,
              validTill: dayjs().add(choosenPlan.duration, "days"),
              subscriptionIdentifier: "FreeSubscription",
            })
            .then(() => {
              setIsLoading(false);
              setActiveIndex(steps.length - 1);
            })
            .catch((err) => {
              setIsLoading(false);
              getErr(err);
            });
        } else {
          setIsLoading(false);
        }
      } else {
        setIsRedirected(true);
        setIsLoading(false);
        setActiveIndex(activeIndex + 1);
      }
    } else if (name === "next" && activeIndex < steps.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  }

  function handlePlanChoose(plan) {
    setChoosenPlan(plan);
  }

  function handleModalClose() {
    setIsLoading(false);
    navigate("#pricing");
    setActiveIndex(user ? 1 : 0);
    localStorage.removeItem("paymentStarted");
    onClose && onClose();
  }

  function handleTransactionComlete() {
    setActiveIndex(steps.length - 1);
  }

  function handleAfterLogin() {
    localStorage.setItem("paymentStepperOpen", true);
    window.location.reload();
  }

  return (
    <Modal open={isOpen} className={styles.container} onClose={handleModalClose}>
      <Slide className="slide-container" direction="up" in={isOpen}>
        <div className="modal-div">
          <Card className="content-wrapper">
            <div className="close-icon-container">
              <IconButton onClick={handleModalClose} className="close-icon-button">
                <CloseIcon size={16} color={colors.mainFont} />
              </IconButton>
            </div>
            <Stepper className="stepper-container" activeStep={activeIndex} alternativeLabel>
              {steps.map((step) => (
                <Step
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": { color: colors.success },
                    "& .MuiStepLabel-root .Mui-active": { color: colors.primary },
                  }}
                  key={step.label}
                >
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <SwitchContent activeIndex={activeIndex} className="switch-container">
              <StepperLogin onLogin={handleAfterLogin} />
              <ChoosePlan onChoose={handlePlanChoose} defaultSelected={defaultPricing} />
              <PaymentProcessing
                planId={choosenPlan.id}
                isRedirected={isRedirected}
                onCompleteTransaction={handleTransactionComlete}
              />
              <PaymentConfirmation />
            </SwitchContent>
            <StepperMoveButtons
              isLoading={isLoading}
              isNext={steps[activeIndex].nextButton}
              isPrev={steps[activeIndex].prevButton}
              onClick={handleStepMove}
              containerClass="move-buttons-container"
            />
          </Card>
        </div>
      </Slide>
    </Modal>
  );
}

export default PaymentStepper;

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s;
    z-index: 1000 !important;
    .close-icon-container {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .slide-container {
      outline: 0;
    }
    .content-wrapper {
      box-sizing: border-box;
      position: relative;
      min-width: 0px;
      width: 100%;
      padding: 30px;
      padding-top: 50px;
      box-shadow: 0px 25px 33px #00000057;
    }
    .stepper-container {
      margin-bottom: 30px;
    }

    @media (max-width: 700px) {
      display: block;
      .slide-container {
        height: 100%;
      }
      .switch-container {
        height: 80% !important;
        overflow-y: auto;
      }
      .content-wrapper {
        position: absolute;
        border-radius: 0;
        width: 100%;
        height: 100%;
      }
      .move-buttons-container {
        position: relative;
        width: 100%;
        bottom: 40px;
      }
    }
  `,
};
