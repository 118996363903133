function StopClockIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"} viewBox="0 0 18.261 20">
      <path
        d="M9.13 20a9.13 9.13 0 01-.869-18.218V1.3h-.869V0h3.478v1.3H10v.478A9.13 9.13 0 019.13 20zm0-17.391a8.261 8.261 0 108.261 8.26 8.27 8.27 0 00-8.261-8.26zm1.311 10.006a.437.437 0 01-.314-.134l-.772-.772a.869.869 0 01-1.094-.84.856.856 0 01.031-.224L4.475 6.829a.435.435 0 11.616-.615l3.814 3.816A.861.861 0 019.13 10a.87.87 0 01.869.869.856.856 0 01-.031.224l.773.773a.435.435 0 01-.3.748zm6.448-8.368L14.882 2.24l.872-.872 2.007 2.007-.871.871z"
        data-name="Shape Copy 5"
      ></path>
    </svg>
  );
}

export default StopClockIcon;
