import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { css } from "@emotion/css";
import colors from "../constants/colors";

function StepperMoveButtons({ onClick, isNext, isPrev, isLoading, containerClass }) {
  return isNext || isPrev ? (
    <div className={`${styles.container} ${containerClass}`}>
      <LoadingButton disabled={!isPrev} name="prev" onClick={onClick} startIcon={<ArrowBackIcon />} className="move-button">
        Prev
      </LoadingButton>
      <LoadingButton
        loading={isLoading}
        disabled={!isNext}
        name="next"
        onClick={onClick}
        variant="contained"
        endIcon={!isLoading && <ArrowForwardIcon />}
        className={`move-button next ${!isNext ? "disabled" : ""} ${isLoading ? "loading" : ""}`}
      >
        {isLoading ? "" : "Next"}
      </LoadingButton>
    </div>
  ) : null;
}

export default StepperMoveButtons;

const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    .move-button {
      width: 80px;
      color: ${colors.primary};
      border-radius: 10px;
    }
    .move-button.next {
      width: 80%;
      color: #fff;
      background: ${colors.primary};
    }
    .move-button.next.loading {
      color: #7e7e7e;
      background: #bbbbbb;
    }
    .move-button.next.disabled {
      color: #7e7e7e;
      background: #00000021;
    }
  `,
};
