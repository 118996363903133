import React from "react";

function SpinnerIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width={size || "50"}
      height={size || "50"}
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <rect width="8" height="19" x="46" y="4.5" fill={color || "#000"} rx="2.85" ry="2.85">
        <animate
          attributeName="opacity"
          begin="-1.1666666666666667s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(24 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-1.0833333333333333s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(48 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-1s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(72 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.9166666666666666s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(96 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.8333333333333334s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(120 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.75s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(144 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.6666666666666666s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(168 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.5833333333333334s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(192 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.5s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(216 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.4166666666666667s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(240 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.3333333333333333s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(264 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.25s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(288 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.16666666666666666s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(312 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="-0.08333333333333333s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
      <rect
        width="8"
        height="19"
        x="46"
        y="4.5"
        fill={color || "#000"}
        rx="2.85"
        ry="2.85"
        transform="rotate(336 50 50)"
      >
        <animate
          attributeName="opacity"
          begin="0s"
          dur="1.25s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="1;0"
        ></animate>
      </rect>
    </svg>
  );
}

export default SpinnerIcon;
