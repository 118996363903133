import { useState } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import TextInput from "../common/TextInput";
import httpService, { getErr } from "../auth/httpServices";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import FormGroup from "@mui/material/FormGroup";
import { checkValidation } from "../common/checkValidation";
import config from "../config.json";
import AuthCode from "react-auth-code-input";
import OpenEmailIcon from "../icons/openEmailIcon";
import NavigateBackButton from "../common/navigateBackButton";

function EmailCode(props) {
  const [authCode, setAuthCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputError] = useState({
    authCode: { isValid: true, errorMessage: "" },
  });

  const navigate = useNavigate();
  const location = useLocation();

  function handleChange(value) {
    setAuthCode(value);
    setInputError({ authCode: { isValid: true, errorMessage: "" } });
  }

  function handleSubmit(e) {
    setIsLoading(true);
    checkValidation({ authCode }, setInputError)
      .then(() => {
        httpService
          .post(config.clientForgotPasswordValidateEndPoint, { token: authCode, email: location.state.email })
          .then((results) => {
            setIsLoading(false);
            navigate("new-password", { state: { validateToken: results.data.validateToken }, replace: true });
          })
          .catch((error) => {
            setIsLoading(false);
            getErr(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        return;
      });
  }

  return (
    <div className={styles.container}>
      <NavigateBackButton containerClassName="back-container" text="Back" />
      <OpenEmailIcon size={80} color={colors.primary} />
      <h4>Check your e-mail</h4>
      <p>We have sent recovery code to your email, please fill it below.</p>
      <AuthCode inputClassName="input" value={authCode} onChange={handleChange} />
      {!inputErrors.authCode.isValid && <p className="error-message">{inputErrors.authCode.errorMessage}</p>}
      <LoadingButton loading={isLoading} onClick={handleSubmit} variant="contained" className="button">
        Submit
      </LoadingButton>
    </div>
  );
}

export default EmailCode;

const styles = {
  container: css`
    .back-container {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px;
    }
    .input {
      width: 40px;
      height: 40px;
      margin: 20px 7px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #d3d3d3;
      box-shadow: 0px 0px 8px 0px #cccccc;
      font-size: 25px;
      color: ${colors.mainFont};
    }
    .input:focus {
      border: 3px solid ${colors.primary};
      border-radius: 5px;
    }
    .button {
      height: 50px;
      background: ${colors.primary};
      border-radius: 15px;
      margin: 20px 0;
    }
    .button:hover {
      background: ${colors.primary};
      opacity: 0.9;
    }
    .error-message {
      color: ${colors.warning};
    }
  `,
};
