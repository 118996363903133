function NotificationBellIcon({ size, color }) {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"}>
      <defs>
        <style>{".cls-1{fill:#101820}"}</style>
      </defs>
      <g data-name="Layer 31" id="Layer_31">
        <path
          className="cls-1"
          d="M27 27H5a1 1 0 01-.89-1.45 18.14 18.14 0 001.89-8V14a10 10 0 0120 0v3.53a18.14 18.14 0 001.89 8A1 1 0 0127 27zM6.55 25h18.9A20.14 20.14 0 0124 17.53V14a8 8 0 00-16 0v3.53A20.14 20.14 0 016.55 25z"
        />
        <path
          className="cls-1"
          d="M16 31a5 5 0 01-5-5 1 1 0 012 0 3 3 0 00.88 2.12 3.08 3.08 0 004.24 0 1 1 0 011.42 1.42A5 5 0 0116 31zM16 6a1 1 0 01-1-1V2a1 1 0 012 0v3a1 1 0 01-1 1zM26 5a2 2 0 112-2 2 2 0 01-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0z"
        />
      </g>
    </svg>
  );
}

export default NotificationBellIcon;
