import jwtDecode from "jwt-decode";

export function login(jwt) {
  localStorage.setItem("token", jwt);
}

export function logout() {
  localStorage.removeItem("token");
}

export function getJwt() {
  return localStorage.getItem("token");
}

export function getCurrentUser() {
  try {
    const jwtToken = localStorage.getItem("token");
    return jwtDecode(jwtToken);
  } catch (error) {
    return null;
  }
}
