import { useState } from "react";
import config from "../config.json";
import { login, getCurrentUser } from "../auth/authService";
import httpService, { getErr } from "../auth/httpServices";
import { Link } from "react-router-dom";
import LoginForm from "./loginForm";
import Card from "../common/card";
import LoadingButton from "../common/LoadingButton";
import { css } from "@emotion/css";
import { style } from "@mui/system";
import colors from "../constants/colors";

const user = getCurrentUser();

function StepperLogin({ onLogin }) {
  const [{ email, password, rememberMe }, setLoginInfo] = useState({ email: "", password: "", rememberMe: false });
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    const { name, value, checked } = e.target;
    if (name === "rememberMe") {
      setLoginInfo((prev) => ({ ...prev, [name]: checked }));
    } else {
      setLoginInfo((prev) => ({ ...prev, [name]: value }));
    }
  }

  function handleLogin() {
    setIsLoading(true);
    httpService
      .post(config.clientLoginEndPoint, {
        email,
        password,
      })
      .then((results) => {
        login(results.headers.authorization);
        setIsLoading(false);
        onLogin && onLogin();
      })
      .catch((error) => {
        getErr(error);
        setIsLoading(false);
      });
  }

  return (
    <Card className={styles.contanier}>
      <LoginForm
        onSubmit={handleLogin}
        emailValue={email}
        passwordValue={password}
        rememberMeChecked={rememberMe}
        isLoading={isLoading}
        onChange={handleChange}
        resetPasswordLink="/login/reset-password"
      />
      <span>
        Don't have an account? Try to <Link to="/login/signup">Signup</Link>
      </span>
      {user && (
        <div className="logout-container">
          <h5>You are already logged in</h5>
          <LoadingButton className="button">Logout</LoadingButton>
        </div>
      )}
    </Card>
  );
}

export default StepperLogin;

const styles = {
  contanier: css`
    background: #fff;
    text-align: center;
    box-shadow: none;
    padding: 0 30px;
    padding-bottom: 20px;
    .logout-container {
      position: absolute;
      background: #ffffffbe;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      z-index: 1;
    }
    .logout-container .button {
      width: 150px;
      background: ${colors.secondary};
    }
  `,
};
