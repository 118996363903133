function OpenEmailIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || 512} height={size || 512} viewBox="0 0 512 512">
      <path
        fill="#7083ed"
        d="M512 177.568V224L256 336 0 224v-46.432c0-10.944 5.6-21.12 14.816-27.008L247.52 2.432a16.045 16.045 0 0116.96 0L497.184 150.56C506.4 156.448 512 166.624 512 177.568z"
      ></path>
      <path
        fill="#ECEFF1"
        d="M416 0H96C78.368 0 64 14.368 64 32v352c0 8.832 7.168 16 16 16h352c8.832 0 16-7.168 16-16V32c0-17.632-14.336-32-32-32z"
      ></path>
      <g fill="#90A4AE">
        <path d="M144 96h224c8.832 0 16-7.168 16-16s-7.168-16-16-16H144c-8.832 0-16 7.168-16 16s7.168 16 16 16zM368 128H144c-8.832 0-16 7.168-16 16s7.168 16 16 16h224c8.832 0 16-7.168 16-16s-7.168-16-16-16zM272 192H144c-8.832 0-16 7.168-16 16s7.168 16 16 16h128c8.832 0 16-7.168 16-16s-7.168-16-16-16z"></path>
      </g>
      <path fill={color || "#000"} d="M512 224v256c0 17.6-14.4 32-32 32H32c-17.6 0-32-14.4-32-32V224l256 112 256-112z"></path>
    </svg>
  );
}

export default OpenEmailIcon;
