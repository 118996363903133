import { Link as ScrollLink } from "react-scroll";
import { css } from "@emotion/css";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUser } from "../auth/authService";

const user = getCurrentUser();

function NavLinks({ onClickLink }) {
  const navigate = useNavigate();

  function handleClick(e) {
    navigate(`/${e.currentTarget.hash}`);
    onClickLink && onClickLink(e);
  }

  return (
    <div>
      <div className={styles.userAccount}>
        <Avatar onClick={() => navigate(user ? "/my-account" : "/login")} className="avatar" />
        {user ? (
          <div>
            <Link to="/my-account">My Account</Link>
          </div>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </div>
      <Grid className={styles.linksContainer} container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ScrollLink onClick={handleClick} activeClass="current" href="#home" to="home" spy={true} smooth={true} offset={-70} duration={500}>
            Home
          </ScrollLink>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ScrollLink onClick={handleClick} activeClass="current" href="#features" to="features" spy={true} smooth={true} offset={-70} duration={500}>
            Features
          </ScrollLink>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ScrollLink onClick={handleClick} activeClass="current" href="#app" to="app" spy={true} smooth={true} offset={-70} duration={500}>
            App Screens
          </ScrollLink>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <ScrollLink onClick={handleClick} activeClass="current" href="#pricing" to="pricing" spy={true} smooth={true} offset={-70} duration={500}>
            Pricing
          </ScrollLink>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
}

export default NavLinks;

const styles = {
  linksContainer: css`
    a {
      color: #36324a;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
    a:hover {
      color: #3f51b5;
    }
    hr {
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  `,
  userAccount: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    a {
      background: #eee;
      padding: 0 15px;
      margin-top: 10px;
      border-radius: 8px;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `,
};
