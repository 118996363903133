import React from "react";
import Layout from "../components/layout";
import NavBar from "../components/navBar";
import MobileMenu from "../components/MobileMenu";
import Footer from "../components/footer";
import { css } from "@emotion/css";

function RefundPolicy() {
  return (
    <Layout pageTitle="Spoguide || Refund Policy">
      <NavBar secondaryPage />
      <MobileMenu />
      <section className={styles.container}>
        <h1>REFUND POLICY</h1>
        <ol>
          <li className="heading">MONEY-BACK GUARANTEE RULES</li>
          <p>
            In addition to refund rights available under applicable laws, if you made a purchase directly on our website or our app and the money-back
            option was presented to you during the checkout, you are eligible to receive a refund provided that all of the following conditions are
            met.
          </p>
          <ol>
            <li>you contact us via email info@spoguide.com within 7 days after the successful purchase and explain why you want the refund.</li>
            <li>you have followed the workout plan for at least 3 consecutive days within the first 7 days after the purchase.</li>
          </ol>
          <p>We will review your application and notify you (by email) whether your application is approved.</p>
          <h5>IMPORTANT STATEMENT</h5>
          <p>
            Please note that only fulfillment of all the above requirements allows you to receive a full Voluntary Refund under “Money-back
            guarantee”. For the sake of clarity, this “Money-back guarantee” does not apply to the following cases:
          </p>
          <ol>
            <li>Personal reasons (you don’t like the product, it did not meet your expectations etc.);</li>
            <li>
              Financial reasons (you did not expect that you will be charged, that the trial will be converted into subscription, that the
              subscription will automatically renew, or that the services are paid etc.).
            </li>
          </ol>
          <li className="heading">GENERAL REFUND RULES</li>
          <p>
            Generally, if you do not meet the conditions set out above, the fees you have paid are non-refundable and/or non-exchangeable, unless
            otherwise is stated herein or is required by applicable law.
          </p>
          <p>
            <strong>Note for residents of certain US states.</strong> If you reside in California or Connecticut and cancel the purchase at any time
            prior to midnight of the third business day after the date of such purchase, we will return the payment you have made.
          </p>
          <p>
            <strong>Note for the EU residents:</strong>
            If you are an EU resident, you have the right to withdraw from the agreement for purchase of digital content without charge and without
            giving any reason within fourteen (7) days from the date of such agreement conclusion. The withdrawal right does not apply if the
            performance of the agreement has begun with your prior express consent and your acknowledgment that you thereby lose your right of
            withdrawal.
            <strong>
              You hereby expressly consent to the immediate performance of the agreement and acknowledge that you will lose your right of withdrawal
              from the agreement once our servers validate your purchase and the applicable purchase is successfully delivered to you.
            </strong>
            Therefore, you will not be eligible for a refund, unless the digital content is defective.
          </p>
        </ol>
        <p>Last updated: 15 September 2022</p>
      </section>
      <Footer />
    </Layout>
  );
}

export default RefundPolicy;

const styles = {
  container: css`
    padding: 50px;
    h1 {
      text-align: center;
      background: #f7f6f9;
      padding: 100px 0;
      border-radius: 15px;
    }
    .heading {
      font-weight: bold;
      font-size: 22px;
    }
  `,
};
