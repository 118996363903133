function HamburgerIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || 100} height={size || 100} viewBox="0 0 310 259.344" version="1.1">
      <g transform="translate(10.016 -803.031)">
        <g
          fill="none"
          stroke={color || "#000"}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="49.336"
        >
          <path d="M19.668 1032.694h250.646"></path>
          <path d="M19.668 932.694h250.646"></path>
          <path d="M19.668 832.694h250.646"></path>
        </g>
      </g>
    </svg>
  );
}

export default HamburgerIcon;
