import { useEffect, useState, useContext } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import Card from "../common/card";
import Radio from "@mui/material/Radio";
import { PricingsContext } from "../context/pricingsContext";

function ChoosePlan({ onChoose, defaultSelected }) {
  const [selected, setSelected] = useState(defaultSelected);

  const { userPricings } = useContext(PricingsContext);

  useEffect(() => {
    onChoose && onChoose(selected);
    localStorage.setItem("selectedPricing", JSON.stringify(selected));
  }, [selected, onChoose]);

  function handleClick(option) {
    setSelected(option);
  }

  return (
    <div className={styles.container}>
      {userPricings &&
        userPricings.map((option) => (
          <Card key={option.id} className="card" isActive={selected.id === option.id}>
            <Radio
              checked={option.id === selected.id}
              onClick={() => handleClick(option)}
              sx={{
                color: colors.primary,
                "&.Mui-checked": {
                  color: colors.primary,
                },
              }}
            />
            <div className="option-content">
              <h5>{option.name}</h5>
              <p>{option.description}</p>
              <h6>{option.currencySymbol + String(option.price)}</h6>
            </div>
            <div className="click-space" onClick={() => handleClick(option)}></div>
          </Card>
        ))}
    </div>
  );
}

export default ChoosePlan;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    .click-space {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #0000;
      top: 0;
    }
    .card {
      position: relative;
      text-align: left;
      box-shadow: none;
      min-width: 0;
      min-height: 190px;
      width: 180px;
      margin: 10px;
      outline-offset: 4px;
      background: #fff;
    }
    .option-content {
      padding: 0 20px;
      text-align: center;
    }
    .option-content h6 {
      margin: 0;
      color: #107c08;
    }
    .option-content h5 {
      margin: 10px 0;
      color: ${colors.primary};
    }
    .option-content p {
      margin: 0;
    }
    @media (max-width: 470px) {
      .card {
        width: 100%;
        min-height: 0px;
      }
      .option-content {
        padding-bottom: 20px;
      }
    }
  `,
};
