import React from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";

function Maintenance() {
  return (
    <div className={styles.container}>
      <img className="logo" src="/logo.png" width="200" alt="spoguide" />
      <img className="maintenance-image" src="/maintenance.png" width="800" alt="spoguide" />
      <p className="title">Hang on! We are under maintenance</p>
      <p className="subTitle">It will not take a long time till we get the error fixed.</p>
    </div>
  );
}

export default Maintenance;

const styles = {
  container: css`
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo {
      margin-bottom: 80px;
    }

    .title {
      font-weight: bold;
      font-size: 32px;
    }
    .subTitle {
      font-size: 22px;
    }

    @media (max-width: 470px) {
      padding: 30px;
      box-sizing: border-box;
      .logo {
        width: 150px;
        margin-bottom: 30px;
      }
      .maintenance-image {
        width: 350px;
      }
      .title {
        font-weight: bold;
        font-size: 22px;
      }
      .subTitle {
        font-size: 16px;
      }
    }
  `,
};
