import { useState } from "react";
import SectionTitle from "./SectionTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css/bundle";
import icon1 from "../icons/checklistIcon";
import icon2 from "../icons/measurementsIcon";
import icon3 from "../icons/compareStaticsIcon";
import icon4 from "../icons/stopClockIcon";
import icon5 from "../icons/exerciseVideoIcon";
import icon6 from "../icons/notificationBellIcon";
import FeatureCard from "./featureCard";
import { css } from "@emotion/css";
import Grid from "@mui/material/Grid";
import colors from "../constants/colors";

const WIDTH = window.screen.width;
const FEATURES_DATA = [
  {
    title: "Exercises Tracking",
    text: "Save the finished exercises and review your history to track your performance.",
    icon: icon1,
  },
  {
    title: "Measurements Tarcking",
    text: "Save your measurements and keep tracking your transformation.",
    icon: icon2,
  },
  {
    title: "Compare Measurements Tool",
    text: "Compare your saved measurements by selecting two dates to show the differences",
    icon: icon3,
  },
  {
    title: "Exercises Rest Timer",
    text: "Keep informed by rest timer to make sure you are not resting more than it should be between the exercises sets.",
    icon: icon4,
  },
  {
    title: "View Exercise",
    text: "Watch your exercise while you move between exercises easily in just one click.",
    icon: icon5,
  },
  {
    title: "Get Notified",
    text: "Get notified when your resting time is finished between exercises sets if you are out of the app.",
    icon: icon6,
  },
];

function Features() {
  const [moveSlideStatus, setMoveSlideStatus] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className={styles.container} id="features">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sm={12} className="grid-item">
          <SectionTitle text="Our app features are the first of its kind in the world of fitness apps" title="Advanced Features" />
        </Grid>
        <Grid item sm={12} className="grid-item">
          <div className={styles.activeCard}>
            <div className="active-border" />
          </div>
          <Swiper
            centeredSlides={true}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            className="swiper-container"
            spaceBetween={50}
            slidesPerView={3}
            breakpoints={{
              0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                autoHeight: true,
              },
              600: {
                slidesPerView: 3,
                slidesPerGroup: 1,
                autoHeight: true,
              },
            }}
            loop={true}
            pagination={{ clickable: true, el: ".swiper-pagination", type: "bullets" }}
            onSliderFirstMove={(e) => {
              setMoveSlideStatus(true);
            }}
            onSlideResetTransitionEnd={() => setMoveSlideStatus(false)}
            onSlideChangeTransitionEnd={(e) => {
              setActiveSlide(e.realIndex);
              setMoveSlideStatus(false);
            }}
          >
            {FEATURES_DATA.map((feature, index) => (
              <SwiperSlide key={`feature-post-${index}`}>
                {(slide) => {
                  return (
                    <div>
                      <FeatureCard
                        status={slide}
                        moveStatus={moveSlideStatus}
                        title={feature.title}
                        text={feature.text}
                        icon={<feature.icon size={100} />}
                      />
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination" />
        </Grid>
      </Grid>
      {/* <div className="bullets-container">
        {FEATURES_DATA.map((feature, index) => (
          <div key={index} className={`swiper-bullets swiper-bullets-inactive ${index === activeSlide && "swiper-bullets-active"}`} />
        ))}
      </div> */}
    </div>
  );
}

export default Features;

const styles = {
  container: css`
    width: 100%;
    .swiper-container {
      min-height: 530px;
      @media screen and (max-width: 600px) {
        .swiper-wrapper {
          width: ${WIDTH}px;
        }
      }
    }

    .swiper-pagination {
      height: 20px;
      margin: 0;
      display: flex;

      justify-content: center;
      align-items: center;
    }
    .grid-item {
      position: relative;
    }
    .swiper-pagination-bullet {
      /* display: inline-block; */
      height: 7px;
      width: 7px;
      bottom: 0;
      /* margin: 5px; */
      /* border-radius: 50%; */
      -webkit-transition: 0.2s all linear;
      transition: 0.2s all linear;
    }
    .swiper-bullets-inactive {
      background: ${colors.secondary};
      margin: 3px;
    }
    .swiper-pagination-bullet-active {
      background: ${colors.primary};
      padding: 3px;
    }
  `,
};
