import React from "react";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import Button from "../common/button";

function CtaTwo() {
  return (
    <div className={styles.constainer}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={10} lg={7}>
          <h1>
            Download our App Today &<br />
            Experience Endless Possibilities.
          </h1>
          <h3>and get started with a free 1 month trial</h3>
        </Grid>
        <Grid item xs={12}>
          <Button href="#" text="Download App" reverse className="button" />
        </Grid>
      </Grid>
    </div>
  );
}

export default CtaTwo;

const styles = {
  constainer: css`
    text-align: center;
    padding: 100px 0;
    h1 {
      font-size: 50px;
      font-weight: 600;
      line-height: 60px;
      margin-bottom: 18px;
      font-family: "Poppins", sans-serif;
      color: #36324a;
    }
    h3 {
      font-size: 24px;
      color: #6f6c7f;
      font-weight: 400;
      margin-bottom: 80px;
    }
    .button {
      width: 200px;
      margin: 0 auto;
      background: ${colors.primary};
      a {
        color: #fff;
      }
    }
  `,
};
