import React from "react";
import { default as Button } from "@mui/lab/LoadingButton";
import { css } from "@emotion/css";
import colors from "../constants/colors";

function LoadingButton({ children, className, ...props }) {
  return (
    <div className={styles.container}>
      <Button {...props} variant="contained" className={`button ${className}`}>
        {children}
      </Button>
    </div>
  );
}

export default LoadingButton;

const styles = {
  container: css`
    .button {
      width: 100%;
      height: 50px;
      background: ${colors.primary};
      border-radius: 15px;
    }
    .button:hover {
      background: ${colors.primary};
      opacity: 0.9;
    }
  `,
};
