import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { css } from "@emotion/css";

let active = 0;

function SwitchContent({ children, activeIndex, direction, className }) {
  const [status, setStatus] = useState("start");
  const [currentHeight, setCurrentHeight] = useState(null);

  const heightRef = useRef(null);

  function moveStatus() {
    if (activeIndex > active) {
      setStatus("next");
    } else if (activeIndex < active) {
      setStatus("previous");
    }
    active = activeIndex;
  }

  //this resizeObserver fires when detecet height changes
  const resizeObserver = new ResizeObserver((entries) => {
    if (entries[0].target.clientHeight) {
      setCurrentHeight(entries[0].target.clientHeight);
    }
  });
  heightRef.current && resizeObserver.observe(heightRef.current);

  useEffect(() => {
    moveStatus();
    heightRef.current && setCurrentHeight(heightRef.current.scrollHeight);
  }, [activeIndex]);

  function switchYClasses(index) {
    if (index === activeIndex && status === "next") {
      return "moveFromBottom";
    } else if (index < activeIndex && status === "next") {
      return "moveToTop";
    } else if (index > activeIndex && status === "previous") {
      return "moveToBottom";
    } else if (index < activeIndex && status === "previous") {
      return "moveToBottom";
    } else if ((index > activeIndex || index < activeIndex) && (status === "start" || status === "next")) {
      return "inactive-content";
    } else {
      return "moveFromTop";
    }
  }

  function switchXClasses(index) {
    if (index === activeIndex && status === "next") {
      return "moveFromRight";
    } else if (index < activeIndex && status === "next") {
      return "moveToLeft";
    } else if (index > activeIndex && status === "previous") {
      return "moveToRight";
    } else if (index < activeIndex && status === "previous") {
      return "moveToRight";
    } else if ((index > activeIndex || index < activeIndex) && (status === "start" || status === "next")) {
      return "inactive-content";
    } else {
      return "moveFromLeft";
    }
  }

  function switchXrtlClasses(index) {
    if (index === activeIndex && status === "next") {
      return "moveFromLeft";
    } else if (index < activeIndex && status === "next") {
      return "moveToRight";
    } else if (index > activeIndex && status === "previous") {
      return "moveToLeft";
    } else if (index < activeIndex && status === "previous") {
      return "moveToLeft";
    } else if ((index > activeIndex || index < activeIndex) && (status === "start" || status === "next")) {
      return "inactive-content";
    } else {
      return "moveFromRight";
    }
  }

  return (
    <div className={`${styles.container} ${className}`} style={{ height: currentHeight }}>
      {children && children.length
        ? children.map((child, index) => {
            return (
              <div
                key={index}
                ref={index === activeIndex ? heightRef : null}
                className={`active-content cssanimation ${direction === "y" || !direction ? switchYClasses(index) : ""} ${
                  direction === "x" ? switchXClasses(index) : ""
                } ${direction === "x-rtl" ? switchXrtlClasses(index) : ""}`}
              >
                {child}
              </div>
            );
          })
        : children}
    </div>
  );
}

SwitchContent.defaultProps = {
  activeIndex: 0,
  direction: "x",
};

export default SwitchContent;

const styles = {
  container: css`
    position: relative;
    overflow: hidden;
    transition: height 0.3s;
    .active-content {
      width: 100%;
      position: absolute;
    }
    .inactive-content {
      display: none;
    }
    .cssanimation {
      animation-duration: 0.3s;
    }
  `,
};
