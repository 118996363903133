import { useState } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";

function RadioButton({ children, active, name, value, onClick, className, activeColor, ...rest }) {
  const [isActive, setIsActive] = useState(false);

  function handleActivate(e) {
    setIsActive(!isActive);
    e.currentTarget.name = name;
    e.currentTarget.value = value;
    onClick && onClick(e);
  }

  const status = active !== undefined ? active : isActive;

  const styles = {
    container: css`
      min-height: 20px;
      min-width: 20px;
      padding: 30px;
      background: #fff;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      box-shadow: inset 0 0 0 0 #00000054;
      ::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        box-shadow: 0px 0px 19px 5px #00000054;
        transition: box-shadow 0.3s;
        border-radius: 15px;
        z-index: 0;
      }
      transition: box-shadow 0.3s;
    `,
    active: css`
      box-shadow: inset 0 0 20px 15px #00000054;
      background: ${activeColor || "#00ee"};
      ::before {
        box-shadow: none;
      }
    `,
  };

  return (
    <div {...rest} value={value} name={name} onClick={handleActivate} className={`${styles.container} ${status ? styles.active : ""} ${className}`}>
      {children}
    </div>
  );
}

export default RadioButton;
