import { useState } from "react";
import { css } from "@emotion/css";
import httpService, { getErr } from "../auth/httpServices";
import { login } from "../auth/authService";
import { Link } from "react-router-dom";
import config from "../config.json";
import NavigateBackButton from "../common/navigateBackButton";
import LoginForm from "./loginForm";

function Login() {
  const [{ email, password, rememberMe }, setLoginInfo] = useState({ email: "", password: "", rememberMe: false });

  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    const { name, value, checked } = e.target;
    if (name === "rememberMe") {
      setLoginInfo((prev) => ({ ...prev, [name]: checked }));
    } else {
      setLoginInfo((prev) => ({ ...prev, [name]: value }));
    }
  }

  function handleLogin() {
    setIsLoading(true);
    httpService
      .post(config.clientLoginEndPoint, {
        email,
        password,
      })
      .then((results) => {
        login(results.headers.authorization);
        window.location.replace("/my-account");
      })
      .catch((error) => {
        getErr(error);
        setIsLoading(false);
      });
  }

  return (
    <div className={styles.container}>
      <NavigateBackButton containerClassName="back-container" text="Back" />
      <h4>Hello Again</h4>
      <p>Login so you can manage your account and subscrioption</p>
      <LoginForm
        onSubmit={handleLogin}
        emailValue={email}
        passwordValue={password}
        rememberMeChecked={rememberMe}
        isLoading={isLoading}
        onChange={handleChange}
        resetPasswordLink="reset-password"
      />
      <p className="signup-cta">
        Don't have an account yet ? <Link to="signup">Sign Up</Link>
      </p>
    </div>
  );
}

export default Login;

const styles = {
  container: css`
    .back-container {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px;
    }
    .signup-cta a {
      font-weight: bold;
    }
  `,
};
