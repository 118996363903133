import { css } from "@emotion/css";

function Button({ text, rightIcon, href, onClick, className, value, id }) {
  return (
    <div className={`${styles.container} ${className || ""}`} onClick={onClick}>
      <a href={href} className="btn" value={value} id={id}>
        {text}
        <span>{rightIcon}</span>
      </a>
    </div>
  );
}

export default Button;

const styles = {
  container: css`
    width: 100%;
    min-width: 170px;
    overflow: hidden;
    border-radius: 50px;
    background: #fff;
    .btn {
      display: flex;
      color: #3f51b5;
      cursor: pointer;
      z-index: 1;
      position: relative;
      padding: 16px 0;
      font-weight: 600;
      text-transform: capitalize;
      border-radius: 50px;
      border: 2px solid #3f51b5;

      transition: all 0.3s linear;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      span {
        display: flex;
      }
    }
    .btn:hover {
      color: #fff;
    }
    .btn:hover::before {
      content: "";
      background: #3f51b5;
      position: absolute;
      z-index: -1;
      padding: 0.85em 0.75em;
      display: block;
      transition: all 0.8s ease;
      border-radius: 50%;
      left: -50%;
      right: -50%;
      top: -150%;
      bottom: -150%;
      line-height: 8.34em;
      opacity: 0.3;
    }
    .btn::after {
      content: "";
      background: #3f51b5;
      position: absolute;
      z-index: -1;
      padding: 0.85em 0.75em;
      display: block;
      transition: all 0.6s ease;
      border-radius: 50%;
      left: -50%;
      right: -50%;
      top: -150%;
      bottom: -150%;
      line-height: 8.34em;
      transform: scale(0, 0);
    }
    .btn:hover::after {
      transform: scale(1, 1);
      color: #fff;
    }
  `,
};
