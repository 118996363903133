import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import TextInput from "../common/TextInput";
import PhoneInput from "../common/phone-input/phoneInput";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import RadioButton from "../common/radioButton";
import MaleIcon from "../icons/maleIcon";
import FemaleleIcon from "../icons/femaleIcon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { UserContext } from "../context/userContext";
import httpService, { getErr } from "../auth/httpServices";
import config from "../config.json";
import { checkValidation } from "../common/checkValidation";

function MyAccount() {
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: Date.now(),
    image: "",
    newsLetterSubscribed: false,
  });

  const [inputErrors, setInputError] = useState({
    name: { isValid: true, errorMessage: "" },
    email: { isValid: true, errorMessage: "" },
    phoneNumber: { isValid: true, errorMessage: "" },
    dateOfBirth: { isValid: true, errorMessage: "" },
    gender: { isValid: true, errorMessage: "" },
  });

  useEffect(() => {
    if (currentUser) {
      setInfo({
        name: currentUser.name,
        email: currentUser.email,
        phoneNumber: currentUser.phoneNumber,
        gender: currentUser.gender,
        dateOfBirth: currentUser.dateOfBirth,
        image: currentUser.image,
        newsLetterSubscribed: currentUser.newsLetterSubscribed,
      });
    }
  }, [currentUser]);

  function handleChange(e) {
    const { name, value, checked } = e.currentTarget;
    if (name === "newsLetterSubscribed") {
      setInfo((prev) => ({ ...prev, [name]: checked }));
    } else {
      setInfo((prev) => ({ ...prev, [name]: value }));
    }

    setInputError((prev) => ({
      ...prev,
      [name]: { isValid: true, errorMessage: "" },
    }));
  }

  function handleDateChange(value) {
    setInfo((prev) => ({ ...prev, dateOfBirth: new Date(value).valueOf() }));
  }

  function handlePhoneChange(value) {
    setInfo((prev) => ({ ...prev, phoneNumber: value }));
    setInputError((prev) => ({
      ...prev,
      phoneNumber: { isValid: true, errorMessage: "" },
    }));
  }

  function handleSave() {
    setIsLoading(true);
    checkValidation(info, setInputError)
      .then(() => {
        httpService
          .patch(config.clientEndPoint, info)
          .then((results) => {
            alert(results.data.message);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            getErr(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        return;
      });
  }

  return (
    <div className={styles.container}>
      <h1>My Account</h1>
      <Grid container>
        <Grid item sm={6}>
          <TextInput
            error={!inputErrors.name.isValid}
            helperText={inputErrors.name.errorMessage}
            onChange={handleChange}
            name="name"
            value={info.name}
            className="text-input"
            label="Full Name"
          />
          <TextInput
            error={!inputErrors.email.isValid}
            helperText={inputErrors.email.errorMessage}
            onChange={handleChange}
            name="email"
            value={info.email}
            className="text-input"
            label="Email"
          />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <MobileDatePicker
              className="date-text-field"
              label="Date of Birth"
              value={info.dateOfBirth}
              onChange={handleDateChange}
              renderInput={(params) => <TextInput {...params} />}
            />
          </LocalizationProvider>
          <PhoneInput
            error={!inputErrors.phoneNumber.isValid}
            helperText={inputErrors.phoneNumber.errorMessage}
            onCountryChange={(country) => {
              setInputError((prev) => ({
                ...prev,
                phoneNumber: { isValid: true, errorMessage: "" },
              }));
            }}
            label="Phone"
            name="phoneNumber"
            onChange={handlePhoneChange}
            value={info.phoneNumber}
          />
        </Grid>
        <Grid item sm={6}>
          <div className="genders-container">
            <p className="gender-title">Gender :</p>
            <div className="genders-buttons-container">
              <RadioButton
                onClick={handleChange}
                active={info.gender === "Male" ? true : false}
                activeColor={colors.primary}
                name="gender"
                value="Male"
                className="gender-radio-button"
              >
                <MaleIcon size={50} color={info.gender === "Male" ? "#fff" : colors.primary} />
                <p className={info.gender === "Male" ? "active" : ""}>Male</p>
              </RadioButton>
              <RadioButton
                onClick={handleChange}
                active={info.gender === "Female" ? true : false}
                activeColor={colors.primary}
                name="gender"
                value="Female"
                className="gender-radio-button"
              >
                <FemaleleIcon size={50} color={info.gender === "Female" ? "#fff" : colors.primary} />
                <p className={info.gender === "Female" ? "active" : ""}>Female</p>
              </RadioButton>
            </div>
          </div>
          <FormControlLabel
            control={<Checkbox sx={{ color: colors.primary, "&.Mui-checked": { color: colors.primary } }} />}
            label="Newsletter Subscribe"
            className="newsletter"
            name="newsLetterSubscribed"
            onChange={handleChange}
            checked={info.newsLetterSubscribed}
          />
        </Grid>
      </Grid>
      <div className="save-button-wrapper">
        <LoadingButton loading={isLoading} onClick={handleSave} variant="contained">
          Save
        </LoadingButton>
      </div>
    </div>
  );
}

export default MyAccount;

const styles = {
  container: css`
    padding: 50px;
    h1 {
      text-align: center;
      margin-top: 0;
    }
    .phone {
      width: 90% !important;
      border-radius: 15px !important;
    }
    .phone:focus {
      border-color: ${colors.primary} !important;
    }
    .special-label {
      top: -17px !important;
      left: 9px !important;
      color: ${colors.primary} !important;
    }
    .MuiTextField-root {
      margin-bottom: 20px;
      width: 90%;
    }
    .gender-title {
      margin: 0;
      padding-left: 20px;
      text-align: left;
    }
    .genders-buttons-container {
      display: flex;
    }
    .gender-radio-button {
      width: 80px;
      height: 80px;
      padding: 10px;
      margin: 20px;
    }
    .gender-radio-button::before {
      opacity: 0.4;
    }
    .gender-radio-button p {
      margin: 0;
      margin-top: 10px;
    }
    .gender-radio-button .active {
      color: #fff;
    }
    .gender-radio-button svg {
      margin-top: 10px;
    }
    .newsletter {
      padding-left: 20px;
      margin-top: 20px;
    }
    .save-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
    }
    .MuiButton-contained {
      background: ${colors.primary};
      border-radius: 10px;
    }
    .MuiButton-contained:hover {
      background: ${colors.primary};
      opacity: 0.8;
    }
  `,
};
