function TimelineIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon"
      fill={color || "#000"}
      overflow="hidden"
      viewBox="0 0 1024 1024"
      width={size || "512"}
      height={size || "512"}
    >
      <path d="M981.333 341.333c0 46.934-38.4 85.334-85.333 85.334-7.68 0-14.933-.854-21.76-2.987L722.347 575.147c2.133 6.826 2.986 14.506 2.986 22.186 0 46.934-38.4 85.334-85.333 85.334s-85.333-38.4-85.333-85.334c0-7.68.853-15.36 2.986-22.186l-108.8-108.8c-6.826 2.133-14.506 2.986-22.186 2.986s-15.36-.853-22.187-2.986l-194.133 194.56c2.133 6.826 2.986 14.08 2.986 21.76C213.333 729.6 174.933 768 128 768s-85.333-38.4-85.333-85.333 38.4-85.334 85.333-85.334c7.68 0 14.933.854 21.76 2.987l194.56-194.133c-2.133-6.827-2.987-14.507-2.987-22.187 0-46.933 38.4-85.333 85.334-85.333S512 337.067 512 384c0 7.68-.853 15.36-2.987 22.187l108.8 108.8C624.64 512.853 632.32 512 640 512s15.36.853 22.187 2.987l151.466-151.894a72.533 72.533 0 01-2.986-21.76C810.667 294.4 849.067 256 896 256s85.333 38.4 85.333 85.333z"></path>
    </svg>
  );
}

export default TimelineIcon;
