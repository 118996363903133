import { validator } from "./inputValidation";

function checkValidation(inputs, setErrorState) {
  const keys = Object.keys(inputs);
  return new Promise((resolve, reject) => {
    const wrongInputs = [];

    keys.forEach((input) => {
      const check = validator(input, inputs[input]);
      !check.isValid && wrongInputs.push({ input, ...check });
      setErrorState &&
        setErrorState((prev) => ({
          ...prev,
          [input]: { isValid: check.isValid, errorMessage: check.message },
        }));
    });

    if (!wrongInputs.length) {
      resolve();
    } else {
      reject(wrongInputs);
    }
  });
}

export { checkValidation };
