import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import { UserContext } from "../context/userContext";
import TextInput from "../common/TextInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import httpService, { getErr } from "../auth/httpServices";
import config from "../config.json";
import { checkValidation } from "../common/checkValidation";

function ChangePassword() {
  const { currentUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState({ currentPassword: false, newPassword: false });
  const [values, setValues] = useState({ currentPassword: "", newPassword: "" });
  const [isLoading, setIsLoading] = useState(false);

  const [inputErrors, setInputError] = useState({
    currentPassword: { isValid: true, errorMessage: "" },
    password: { isValid: true, errorMessage: "" },
  });

  function handleClickShowPassword(e) {
    const { name } = e.currentTarget;
    setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function handleSave() {
    checkValidation({ password: values.newPassword }, setInputError)
      .then(() => {
        httpService
          .patch(config.clientChangePasswordEndPoint, values)
          .then((results) => {
            alert(results.data.message);
            setValues({ currentPassword: "", newPassword: "" });
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            getErr(error);
          });
      })
      .catch((error) => {
        return;
      });
  }

  return (
    <div className={styles.container}>
      <h1>Change Password</h1>
      <div className="input-wrapper">
        <TextInput
          type={showPassword.currentPassword ? "text" : "password"}
          label="Current Password"
          onChange={handleChange}
          name="currentPassword"
          value={values.currentPassword}
          error={!inputErrors.currentPassword.isValid}
          helperText={inputErrors.currentPassword.errorMessage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" name="currentPassword" onClick={handleClickShowPassword} edge="end">
                  {showPassword.currentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="input-wrapper">
        <TextInput
          type={showPassword.newPassword ? "text" : "password"}
          label="New Password"
          onChange={handleChange}
          name="newPassword"
          value={values.newPassword}
          error={!inputErrors.password.isValid}
          helperText={inputErrors.password.errorMessage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" name="newPassword" onClick={handleClickShowPassword} edge="end">
                  {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="save-button-wrapper">
        <LoadingButton loading={isLoading} onClick={handleSave} variant="contained">
          Save
        </LoadingButton>
      </div>
    </div>
  );
}

export default ChangePassword;

const styles = {
  container: css`
    padding: 50px;
    h1 {
      text-align: center;
      margin-top: 0;
    }
    .input-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    .save-button-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
    }
    .MuiButton-root {
      background: ${colors.primary};
      border-radius: 10px;
    }
    .MuiButton-root:hover {
      background: ${colors.primary};
      opacity: 0.8;
    }
  `,
};
