import { useState, useEffect } from "react";
import NavLinks from "./navLinks";
import Grid from "@mui/material/Grid";
import { css } from "@emotion/css";
import HamburgerIcon from "../icons/hamburgerIcon";
import colors from "../constants/colors";
import Avatar from "@mui/material/Avatar";
import { getCurrentUser } from "../auth/authService";
import { Link, useNavigate } from "react-router-dom";
import NavigateBackButton from "../common/navigateBackButton";

const user = getCurrentUser();

function NavBar({ onOpen, secondaryPage }) {
  const [sticky, setSticky] = useState(false);

  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className={styles.container} id="home">
      <div className={`nav-container ${sticky ? "fixed-container" : secondaryPage ? "secondary-page" : ""}`}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <div className={styles.logo}>
              <a href="/">
                <img src="/logo.png" width="200" alt="spoguide" />
              </a>
            </div>
            <div onClick={onOpen} className={styles.menu}>
              <HamburgerIcon size={30} color={colors.secondary} />
            </div>
          </Grid>
          <Grid className="navLinks" item xs={12} sm={12} md={7} lg={6}>
            {secondaryPage ? <NavigateBackButton containerClassName="back-container" buttonClass="back-button" text="home" to="/" /> : <NavLinks />}
          </Grid>
          <Grid className="login-container" item xs={12} sm={12} md={2} lg={2}>
            <div className={styles.userAccount}>
              {user ? (
                <div>
                  <Link to="/my-account">My Account</Link>
                </div>
              ) : (
                <Link to="/login">Login</Link>
              )}
              <Avatar onClick={() => navigate(user ? "/my-account" : "/login")} className="avatar" />
            </div>
          </Grid>
        </Grid>
      </div>
      {secondaryPage && <div className="pre-secondary-page"></div>}
    </div>
  );
}

export default NavBar;

const styles = {
  container: css`
    .nav-container {
      position: fixed;
      width: 100%;
      text-align: center;
      z-index: 10;
      transition: background 0.3s;
      @media screen and (max-width: 600px) {
        position: fixed;
      }
      .navLinks {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
    }
    .fixed-container {
      position: fixed;
      background: #fff;
      box-shadow: 0px 0px 15px 1px #00000057;
      animation-duration: 0.5s;
      transition: background 0.3s;
    }
    .pre-secondary-page {
      height: 100px;
    }
    .secondary-page {
      background: #fff;
    }
    .back-container {
      text-align: left;
    }
    .back-button {
      font-size: 16px;
      font-weight: 500;
    }
    @media screen and (max-width: 900px) {
      .login-container {
        display: none;
      }
    }
  `,
  logo: css`
    margin: 10px 0;
    width: 100%;
  `,
  menu: css`
    position: absolute;
    top: 25px;
    right: 30px;
    @media screen and (min-width: 900px) {
      display: none;
    }
  `,
  userAccount: css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 80px;
    border-radius: 15px;
    a {
      margin: 0 20px;
    }
  `,
};
