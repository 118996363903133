import validate from "validate.js";

const types = {
  name: {
    name: {
      presence: { allowEmpty: false, message: "Full name can't be blank" },
    },
  },
  email: {
    email: {
      email: {
        message: "this email doesn't look like a valid email",
      },
    },
  },
  password: {
    password: {
      presence: true,
      length: {
        minimum: 8,
        message: "password must be at least 8 characters",
      },
      format: {
        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
        message: "must contain a capital, lowercase characters,symbols, and numbers",
      },
    },
  },
  dateOfBirth: {
    dateOfBirth: {
      presence: { allowEmpty: false, message: "Date of birth can't be blank" },
    },
  },
  phoneNumber: {
    phoneNumber: {
      presence: { allowEmpty: false },
      length: {
        minimum: 11,
        message: "Phone number is less than 9 numbers",
      },
    },
  },
  newsLetterSubscribed: {
    newsLetterSubscribed: {
      type: "boolean",
    },
  },
  termsAgree: {
    termsAgree: {
      type: "boolean",
      presence: { message: "You need to agree on the Terms and Conditions and the Privacy Policy" },
    },
  },
  gender: {
    gender: {
      presence: { allowEmpty: false, message: "Please select gender" },
    },
  },
  height: {
    height: {
      presence: { allowEmpty: false, message: "Please set height" },
    },
  },
  weight: {
    weight: {
      presence: { allowEmpty: false, message: "Please set weight" },
    },
  },
  heightUnit: {
    heightUnit: {
      presence: { allowEmpty: false, message: "Please set height unit" },
    },
  },
  weightUnit: {
    weightUnit: {
      presence: { allowEmpty: false, message: "Please set weight unit" },
    },
  },
  daysPerWeek: {
    daysPerWeek: {
      presence: { allowEmpty: false, message: "Please select how many days you plan to exercise per week" },
    },
  },
  goal: {
    goal: {
      presence: { allowEmpty: false, message: "Please select your goal" },
    },
  },
  lastExercise: {
    lastExercise: {
      presence: { allowEmpty: false, message: "Please select when you last time exercises" },
    },
  },
  level: {
    level: {
      presence: { allowEmpty: false, message: "Please select your level" },
    },
  },
  period: {
    period: {
      presence: { allowEmpty: false, message: "Please select targeted workout period" },
    },
  },
  authCode: {
    authCode: {
      presence: { allowEmpty: false },
      length: {
        minimum: 6,
        message: "Code should not be less than 6 digits",
      },
    },
  },
};

function validator(type, value) {
  validate.options = { fullMessages: false };
  const doValidate = validate({ [type]: value }, types[type]);
  return !doValidate ? { isValid: true } : { isValid: false, message: doValidate[type][0] };
}

export { validator };
