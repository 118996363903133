import { useState, useEffect, forwardRef } from "react";
import { css } from "@emotion/css";
import colors from "../constants/colors";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import TextInput from "../common/TextInput";
import httpService, { getErr } from "../auth/httpServices";
import { login } from "../auth/authService";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import config from "../config.json";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import PhoneInput from "../common/phone-input/phoneInput";
import { checkValidation } from "../common/checkValidation";
import NavigateBackButton from "../common/navigateBackButton";

function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    status: true,
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    country: "",
    dateOfBirth: Date.now(),
    image: "",
    planGoal: "",
    gender: "",
    weight: 1,
    height: 1,
    newsLetterSubscribed: false,
    termsAgree: false,
  });

  const [inputErrors, setInputErrors] = useState({
    name: { isValid: true, errorMessage: "" },
    email: { isValid: true, errorMessage: "" },
    password: { isValid: true, errorMessage: "" },
    phoneNumber: { isValid: true, errorMessage: "" },
    dateOfBirth: { isValid: true, errorMessage: "" },
    termsAgree: { isValid: true, errorMessage: "" },
  });

  function handleChange(e) {
    const { value, name, checked } = e.target;
    if (name === "newsLetterSubscribed" || name === "termsAgree") {
      setUser((prev) => ({ ...prev, [name]: checked }));
    } else {
      setUser((prev) => ({ ...prev, [name]: value }));
    }
    setInputErrors((prev) => ({ ...prev, [name]: { isValid: true, errorMessage: "" } }));
  }

  function handleDateChange(value) {
    setUser((prev) => ({ ...prev, dateOfBirth: new Date(value).valueOf() }));
  }

  function handlePhoneChange(value, country) {
    setUser((prev) => ({ ...prev, phoneNumber: value, country: country.code }));
    setInputErrors((prev) => ({ ...prev, phoneNumber: { isValid: true, errorMessage: "" } }));
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleSignup() {
    const { name, email, password, phoneNumber, termsAgree, dateOfBirth, weight, height } = user;
    setIsLoading(true);
    checkValidation({ name, email, password, phoneNumber, dateOfBirth, termsAgree: !user.termsAgree ? null : termsAgree }, setInputErrors)
      .then(() => {
        httpService
          .post(config.clientRegisterEndPoint, { ...user, weight_kg: weight, height_cm: height })
          .then((results) => {
            login(results.headers.authorization);
            window.location.replace("/my-account");
          })
          .catch((error) => {
            setIsLoading(false);
            getErr(error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        return;
      });
  }

  return (
    <div className={styles.container}>
      <NavigateBackButton containerClassName="back-container" text="Back to login" />
      <h4>Sign up to Spoguide</h4>
      <FormGroup>
        <div className="inputs-container">
          <TextInput
            error={!inputErrors.name.isValid}
            helperText={inputErrors.name.errorMessage}
            type="text"
            onChange={handleChange}
            value={user.name}
            name="name"
            className="text-input"
            label="Full Name"
          />
          <TextInput
            error={!inputErrors.email.isValid}
            helperText={inputErrors.email.errorMessage}
            type="email"
            onChange={handleChange}
            value={user.email}
            name="email"
            className="text-input"
            label="Email"
          />
          <TextInput
            error={!inputErrors.password.isValid}
            helperText={inputErrors.password.errorMessage}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            value={user.password}
            name="password"
            className="text-input"
            label="Password"
          />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <MobileDatePicker
              label="Date of Birth"
              value={user.dateOfBirth}
              onChange={handleDateChange}
              renderInput={(params) => <TextInput className="text-input" {...params} />}
            />
          </LocalizationProvider>
          <PhoneInput
            error={!inputErrors.phoneNumber.isValid}
            helperText={inputErrors.phoneNumber.errorMessage}
            defaultCountry="SA"
            label="Phone"
            name="phoneNumber"
            className="text-input"
            onChange={handlePhoneChange}
            value={user.phoneNumber}
          />
          <div className="checkboxes">
            <FormControlLabel
              onChange={handleChange}
              checked={user.newsLetterSubscribed}
              name="newsLetterSubscribed"
              control={
                <Checkbox
                  sx={{
                    color: colors.primary,
                    "&.Mui-checked": {
                      color: colors.primary,
                    },
                  }}
                />
              }
              label="I would like to get the latest app news and offers"
            />
            <FormControlLabel
              onChange={handleChange}
              checked={user.termsAgree}
              name="termsAgree"
              control={
                <Checkbox
                  sx={{
                    color: colors.primary,
                    "&.Mui-checked": {
                      color: colors.primary,
                    },
                  }}
                />
              }
              label={
                <label>
                  I agree on <Link to="/privacyPolicy">Privacy Policy</Link> and
                  <Link to="/termsAndConditions"> terms & condition</Link>
                </label>
              }
            />
            <label className="terms-error">{inputErrors.termsAgree.errorMessage}</label>
          </div>
        </div>
        <LoadingButton loading={isLoading} onClick={handleSignup} variant="contained" className="button">
          Submit
        </LoadingButton>
      </FormGroup>
    </div>
  );
}

export default Signup;

const styles = {
  container: css`
    .back-container {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px;
    }
    h4 {
      line-height: 60px;
    }
    .inputs-container {
      width: 100%;
      margin: 50px 0;
    }
    .text-input {
      margin-top: 20px;
      width: 100%;
    }
    .phone-input-wrapper {
      margin-top: 20px;
    }
    .checkboxes {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
    .terms-error {
      color: ${colors.warning};
    }
    .button {
      height: 50px;
      background: ${colors.primary};
      border-radius: 15px;
      margin-bottom: 20px;
    }
    .button:hover {
      background: ${colors.primary};
      opacity: 0.9;
    }
  `,
};
