function PlayArrowIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "512"}
      height={size || "512"}
      x="0"
      y="0"
      enableBackground="new 0 0 357 357"
      version="1.1"
      viewBox="0 0 357 357"
      xmlSpace="preserve"
      fill={color || "#000"}
    >
      <path d="M38.25 0L38.25 357 318.75 178.5z"></path>
    </svg>
  );
}

export default PlayArrowIcon;
