import React from "react";

function RenewIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 489.698 489.698"
      version="1.1"
      viewBox="0 0 489.698 489.698"
      xmlSpace="preserve"
      width={size || "512"}
      height={size || "512"}
      fill={color || "#000"}
    >
      <path d="M468.999 227.774c-11.4 0-20.8 8.3-20.8 19.8-1 74.9-44.2 142.6-110.3 178.9-99.6 54.7-216 5.6-260.6-61l62.9 13.1c10.4 2.1 21.8-4.2 23.9-15.6 2.1-10.4-4.2-21.8-15.6-23.9l-123.7-26c-7.2-1.7-26.1 3.5-23.9 22.9l15.6 124.8c1 10.4 9.4 17.7 19.8 17.7 15.5 0 21.8-11.4 20.8-22.9l-7.3-60.9c101.1 121.3 229.4 104.4 306.8 69.3 80.1-42.7 131.1-124.8 132.1-215.4.1-11.4-8.3-20.8-19.7-20.8zM20.599 261.874c11.4 0 20.8-8.3 20.8-19.8 1-74.9 44.2-142.6 110.3-178.9 99.6-54.7 216-5.6 260.6 61l-62.9-13.1c-10.4-2.1-21.8 4.2-23.9 15.6-2.1 10.4 4.2 21.8 15.6 23.9l123.8 26c7.2 1.7 26.1-3.5 23.9-22.9l-15.6-124.8c-1-10.4-9.4-17.7-19.8-17.7-15.5 0-21.8 11.4-20.8 22.9l7.2 60.9c-101.1-121.2-229.4-104.4-306.8-69.2-80.1 42.6-131.1 124.8-132.2 215.3 0 11.5 8.4 20.8 19.8 20.8z"></path>
    </svg>
  );
}

export default RenewIcon;
