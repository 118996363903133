import React from "react";

function AppStoreIcon({ size }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || 100} height={size || 100} viewBox="0 0 48 48">
      <linearGradient id="OpwYZ9nhL01h2sErtedzua" x1="24" x2="24" y1="4.617" y2="40.096" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#33bef0"></stop>
        <stop offset="1" stopColor="#0a85d9"></stop>
      </linearGradient>
      <path
        fill="url(#OpwYZ9nhL01h2sErtedzua)"
        d="M33.9 6H14.1A8.1 8.1 0 006 14.1v19.8a8.1 8.1 0 008.1 8.1h19.8a8.1 8.1 0 008.1-8.1V14.1A8.1 8.1 0 0033.9 6z"
      ></path>
      <path
        d="M12.3 30.977a2.495 2.495 0 01-2.5-2.484 2.495 2.495 0 012.5-2.484h3.798l4.869-8.309-1.423-2.429a2.486 2.486 0 01-.262-1.897 2.48 2.48 0 011.156-1.524c.384-.224.82-.342 1.262-.342.885 0 1.712.474 2.158 1.237l.007.012.006-.011a2.505 2.505 0 012.158-1.237 2.503 2.503 0 012.155 3.765L21.893 26.01h3.328l.293.295c.222.223.425.476.623.774l.197.33c.489.911.598 1.918.319 2.854l-.211.714H12.3z"
        opacity="0.05"
      ></path>
      <path
        d="M12.3 30.477c-1.103 0-2-.89-2-1.984 0-1.094.897-1.984 2-1.984h4.084l5.162-8.809-1.572-2.682c-.27-.461-.345-1-.209-1.518a1.98 1.98 0 01.924-1.219 2.007 2.007 0 011.01-.274c.708 0 1.37.379 1.727.989l.438.749.438-.748a2.005 2.005 0 012.736-.715 2.003 2.003 0 01.713 2.738L21.02 26.509h3.992l.146.147c.198.199.381.427.56.698l.185.31c.418.781.511 1.646.27 2.456l-.106.357H12.3z"
        opacity="0.07"
      ></path>
      <path
        fill="#fff"
        d="M25.302 27.63a4.01 4.01 0 00-.498-.621h-4.656l7.173-12.242a1.498 1.498 0 10-2.587-1.517l-.87 1.484-.87-1.485a1.5 1.5 0 00-2.588 1.516l1.72 2.935-5.455 9.309H12.3c-.829 0-1.5.665-1.5 1.484s.671 1.484 1.5 1.484h13.394a2.696 2.696 0 00-.221-2.058l-.171-.289z"
      ></path>
      <path
        d="M14.5 36.179a2.5 2.5 0 01-2.418-1.87 2.487 2.487 0 01.263-1.896l.713-1.218.44-.088a5.295 5.295 0 011.03-.107l.118.001a3.782 3.782 0 012.766 1.3l.476.542-1.229 2.1a2.511 2.511 0 01-2.159 1.236z"
        opacity="0.05"
      ></path>
      <path
        d="M14.5 35.679c-.354 0-.704-.095-1.01-.275-.46-.27-.789-.704-.924-1.221s-.061-1.056.21-1.517l.6-1.024.22-.044a4.7 4.7 0 01.932-.098l.112.001a3.281 3.281 0 012.396 1.129l.238.271-1.047 1.789a2.008 2.008 0 01-1.727.989z"
        opacity="0.07"
      ></path>
      <path
        fill="#fff"
        d="M14.626 32.002a4.132 4.132 0 00-.932.087l-.487.831a1.5 1.5 0 002.588 1.518l.865-1.477a2.78 2.78 0 00-2.03-.959h-.004z"
      ></path>
      <path
        d="M33.229 36.179a2.51 2.51 0 01-2.158-1.236l-6.027-10.285-.017-.052c-.417-1.289-.335-2.618.214-3.793l1.669-2.858 4.72 8.055h4.07c1.378 0 2.5 1.114 2.5 2.484a2.495 2.495 0 01-2.5 2.484h-1.159l.842 1.437c.338.576.431 1.249.263 1.896s-.579 1.188-1.155 1.526a2.506 2.506 0 01-1.262.342z"
        opacity="0.05"
      ></path>
      <path
        d="M33.229 35.679c-.708 0-1.37-.378-1.727-.988l-6-10.238-.017-.052a4.6 4.6 0 01.208-3.376l1.216-2.081 4.433 7.565H35.7c1.103 0 2 .89 2 1.984a1.994 1.994 0 01-2 1.984h-2.031l1.283 2.19c.271.461.345 1 .21 1.517s-.463.951-.924 1.221a1.996 1.996 0 01-1.009.274z"
        opacity="0.07"
      ></path>
      <path
        fill="#fff"
        d="M35.7 27.009h-4.643l-4.147-7.076-.763 1.303a4.115 4.115 0 00-.185 3.011l5.972 10.191a1.498 1.498 0 002.052.535 1.5 1.5 0 00.535-2.053l-1.725-2.943H35.7c.829 0 1.5-.665 1.5-1.484s-.671-1.484-1.5-1.484z"
      ></path>
    </svg>
  );
}

export default AppStoreIcon;
