import { css } from "@emotion/css";
import colors from "../constants/colors";
import TextField from "@mui/material/TextField";

function TextInput({ label, className, ...rest }) {
  return <TextField {...rest} className={`${styles.textInput} ${className}`} label={label} variant="outlined" />;
}

export default TextInput;

const styles = {
  textInput: css`
    .MuiOutlinedInput-root {
      border-radius: 15px;
    }
    .Mui-focused {
      color: ${colors.primary} !important;
    }
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.primary} !important;
    }
  `,
};
