function LogoutIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"} viewBox="0 0 21.397 21.397">
      <g data-name="logout (1)" transform="translate(-3.204 -1.204)">
        <g data-name="Group 2904" transform="translate(3.254 1.254)">
          <g fill={color || "#000"} stroke={color || "#000"} strokeWidth="0.6" data-name="Group 2903">
            <path
              d="M13.754 15.973a.443.443 0 00-.444.444v3.106a.889.889 0 01-.887.887H1.775a.889.889 0 01-.887-.887V1.775a.889.889 0 01.887-.888h10.648a.889.889 0 01.887.887v3.107a.444.444 0 10.887 0V1.775A1.777 1.777 0 0012.423 0H1.775A1.776 1.776 0 000 1.775v17.747A1.776 1.776 0 001.775 21.3h10.648a1.777 1.777 0 001.777-1.778v-3.106a.443.443 0 00-.446-.443z"
              data-name="Path 2028"
            ></path>
            <path
              d="M21.153 10.322l-5.324-4.881a.444.444 0 10-.6.654l4.484 4.11H5.768a.444.444 0 100 .887h13.945l-4.484 4.11a.444.444 0 00.6.654l5.324-4.881a.444.444 0 000-.654z"
              data-name="Path 2029"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoutIcon;
