import React from "react";

function CompareStaticsIcon({ size, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "512"} height={size || "512"} viewBox="0 0 388.8 388.8">
      <path d="M380.8 317.6h-308V8c0-4.8-3.2-8-8-8s-8 3.2-8 8v309.6H8c-4.8 0-8 3.2-8 8s3.2 8 8 8h48.8v47.2c0 4.8 3.2 8 8 8s8-3.2 8-8v-47.2h308c4.8 0 8-3.2 8-8s-4-8-8-8z"></path>
      <path d="M328 179.2l43.2-45.6c3.2-3.2 3.2-8 0-11.2s-8-3.2-11.2 0l-42.4 44.8-98.4-105.6c-1.6-1.6-4-2.4-5.6-2.4-2.4 0-4 .8-5.6 2.4L100.8 176c-3.2 3.2-3.2 8 .8 11.2 3.2 3.2 8 3.2 11.2-.8l100-108 92.8 100.8-41.6 44-49.6-62.4c-1.6-1.6-3.2-3.2-5.6-3.2-2.4 0-4.8.8-6.4 2.4l-100.8 99.2c-3.2 3.2-3.2 8 0 11.2 1.6 1.6 4 2.4 5.6 2.4 2.4 0 4-.8 5.6-2.4l95.2-93.6 49.6 63.2c1.6 1.6 4 3.2 5.6 3.2 2.4 0 4.8-.8 6.4-2.4l47.2-49.6 42.4 46.4c1.6 1.6 4 2.4 5.6 2.4 1.6 0 4-.8 5.6-2.4 3.2-3.2 3.2-8 .8-11.2L328 179.2z"></path>
    </svg>
  );
}

export default CompareStaticsIcon;
