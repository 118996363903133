import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRout from "./ProtectedRout";
import Account from "../pages/account";
import Home from "../pages/home";
import LoginPageWrapper from "../pages/loginPageWrapper";
import ResetPassword from "../components/resetPassword";
import Login from "../components/login";
import EmailCode from "../components/emailCode";
import NewPassword from "../components/newPassword";
import NotFound from "../pages/notFound";
import Signup from "../components/signup";
import PrivacyPolicyPage from "../pages/privacyPolicy";
import TermsAndConditions from "../pages/termsAndConditions";
import RefundPolicy from "../pages/refundPolicy";

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="checkout" element={null} />
      </Route>
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
      <Route path="/termsAndConditions" element={<TermsAndConditions />} />
      <Route path="/refundPolicy" element={<RefundPolicy />} />
      <Route path="/login" element={<LoginPageWrapper />}>
        <Route path="/login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset-password/email-code" element={<EmailCode />} />
        <Route path="reset-password/email-code/new-password" element={<NewPassword />} />
      </Route>
      <Route element={<ProtectedRout />}>
        <Route path="/my-account" element={<Account />} />
      </Route>
    </Routes>
  );
}

export default MainRoutes;
