import MainRoutes from "./router/mainRoutes";
import { UserProvider } from "./context/userContext";
import { PricingsProvider } from "./context/pricingsContext";
import "./App.css";
import Maintenance from "./pages/maintenance";

const isMobile = "ontouchstart" in document.documentElement && /mobi/i.test(navigator.userAgent);
window.isMobile = isMobile;

function App() {
  return (
    <>
      {/* <Maintenance /> */}
      <UserProvider>
        <PricingsProvider>
          <MainRoutes />
        </PricingsProvider>
      </UserProvider>
    </>
  );
}

export default App;
