import { Divider } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Banner from "../components/banner";
import CtaOne from "../components/ctaOne";
import CtaTwo from "../components/ctaTwo";
import Features from "../components/features";
import Footer from "../components/footer";
import MobileMenu from "../components/MobileMenu";
import NavBar from "../components/navBar";
import PaymentStepper from "../components/paymentStepper";
import Pricings from "../components/pricings";
import Video from "../components/video";
import { PricingsContext } from "../context/pricingsContext";
import { UserContext } from "../context/userContext";

const paymentStepperOpen = JSON.parse(localStorage.getItem("paymentStepperOpen"));
const localSelectedPricing = JSON.parse(localStorage.getItem("selectedPricing"));
const paymentStarted = JSON.parse(localStorage.getItem("paymentStarted"));

function Home() {
  const { pricings, userPricings } = useContext(PricingsContext);
  const { currentUser } = useContext(UserContext);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [pricing, setPricing] = useState(() => {
    if (
      currentUser.subscriptions &&
      currentUser.subscriptions.length > 0 &&
      localSelectedPricing &&
      +localSelectedPricing.price === 0
    ) {
      return userPricings[0];
    } else {
      return localSelectedPricing || {};
    }
  });
  const [isPaymentStepperOpen, setIsPaymentStepperOpen] = useState(() => {
    localStorage.removeItem("paymentStepperOpen");
    return paymentStarted || paymentStepperOpen ? true : false;
  });
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  function handleMobileMenuToggle() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  function handleOpenPaymentStepper(e) {
    if (currentUser.subscriptions) {
      const selectedPricing = userPricings.find(
        (pricing) => String(pricing.id) === String(e.target.id)
      );
      setPricing(selectedPricing ? selectedPricing : userPricings[0]);
    } else {
      const selectedPricing = pricings.find(
        (pricing) => String(pricing.id) === String(e.target.id)
      );
      setPricing(selectedPricing);
    }
    setIsPaymentStepperOpen(true);
  }

  function handleClosePaymentStepper() {
    setIsPaymentStepperOpen(false);
  }
  return (
    <div>
      <MobileMenu isOpen={isMobileMenuOpen} onClickAway={handleMobileMenuToggle} />
      <NavBar onOpen={handleMobileMenuToggle} />
      <Banner />
      <Features />
      <CtaOne />
      <Video />
      <Pricings onClick={handleOpenPaymentStepper} />
      <Divider />
      <CtaTwo />
      <Footer />
      <PaymentStepper
        defaultPricing={pricing}
        isOpen={isPaymentStepperOpen}
        onClose={handleClosePaymentStepper}
      />
    </div>
  );
}

export default Home;
